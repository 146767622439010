export default class DesignerConverter {
	constructor(name, id) {
		this.name = name;
		this.id = id;
		this.designers = [
		{
			"name": "ASTR The Label",
			"id": "pMIWzOPkytfGqlSeYmti"
		},
		{
			"name": "AUI",
			"id": "lLNI2kmmn5GC8ZKu4uVu"
		},
		{
			"name": "Adrianna Papell",
			"id": "oBnEyfFDkFdQ5si6HBIr"
		},
		{
			"name": "Aidan Mattox",
			"id": "GEGqvLVEaD0oWswv8PAp"
		},
		{
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1570727099189-Alice%20and%20olivia%20Size%20Chart%20.jpg?alt=media&token=872f4e45-eeab-4ee9-bdcf-6d4af0375a09",
			"name": "Alice + Olivia",
			"id": "knAXmL0aDPskt0yvHlOt"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1576265242278-andrea%20crocetta%20size%20chaty.jpg?alt=media&token=51662d95-4ec9-4d53-88b9-c3fe4739dfc1",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1576265342019-andrea%20crocetta%20size%20chaty.jpg?alt=media&token=0b16019e-68a4-424e-9225-65a37b8b7684",
			"name": "Andrea Crocetta",
			"id": "C3xtc3VEnmXiCu3mz2wR"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/featured_designers%2F4.%20Anna%20Sui.jpg?alt=media&token=e46c7593-0f53-496f-8bb1-e18481cbca11",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568566912082-Anna-Sui-Sizing-chart.jpg?alt=media&token=fc436abe-f82a-40de-ac99-8b74b144c624",
			"featured": true,
			"name": "Anna Sui",
			"order": 4,
			"id": "CbjJAfTDJCNQ36EBU5KP"
		},
		{
			"name": "Aolixue",
			"id": "BVVncxTisSlRnVug7mIQ"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568566733520-Aviva-On-Earth-General-Size-Chart.jpg?alt=media&token=84d27da4-22db-4239-9208-c7f8ed76b745",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568566906044-Aviva-On-Earth-General-Size-Chart.jpg?alt=media&token=eef85576-c56d-482a-b14e-c72b0d93b99d",
			"name": "Aviva on Earth",
			"id": "Vv7c5DzepomO7Bc9qdqA"
		},
		{
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1570736347975-BCBG%20size%20chart.jpg?alt=media&token=3e83071d-0aed-43b2-aef0-8182152e2cc3",
			"name": "BCBG Maxazria",
			"id": "4t80v11DjMydfChchdcs"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/featured_designers%2F3.%20Balenciaga.jpg?alt=media&token=7aa28298-b76b-41cd-bb5e-062c2d5233f6",
			"featured": true,
			"name": "Balenciaga",
			"order": 3,
			"id": "m2Pm47LecPJCagSaBaGm"
		},
		{
			"name": "Bao Bao Issey Miyake",
			"id": "LRl68wWTgEgYA4zIE4eQ"
		},
		{
			"name": "Bellambia",
			"id": "pgneeZWtxgzCh2qOdil7"
		},
		{
			"name": "Beyond the Runway",
			"id": "JC9v9rwT0SJjbYCwdTRe"
		},
		{
			"name": "Bottega Veneta",
			"id": "eHOLZmAKYU3XxyN0DNcI"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568566764888-Burberry-Sizing-Chart.jpg?alt=media&token=35647c5d-c28a-42d5-9b3d-57aaa0943a18",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568566899451-Burberry-Sizing-Chart.jpg?alt=media&token=556d67c1-92d9-47f9-85da-c17d4f5f0c0f",
			"name": "Burberry",
			"id": "bpjW3y52nh1uMzRjLvXr"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568566802862-calvin-Klein-size-chart-1.jpg?alt=media&token=8ef6394a-e5b1-48fe-96d1-41883720a823",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568566884569-calvin-Klein-size-chart-1.jpg?alt=media&token=44e2d892-4d87-433a-a08f-8ef526e8e087",
			"name": "Calvin Klein",
			"id": "4iKP9KwB21G9EAP6Uoqg"
		},
		{
			"name": "Caylar",
			"id": "QSgd3Fivxbu79DQoa4lF"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/featured_designers%2F5.%20Chloe.jpg?alt=media&token=44a74624-4550-4874-8f2d-5502e04eabfa",
			"featured": true,
			"name": "Chloé",
			"order": 5,
			"id": "lpeeYDIpTnddNWESE9yX"
		},
		{
			"name": "Christian Dior",
			"id": "Qn1WPSub0z7yjqdkgTap"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/featured_designers%2F6.%20Cinq%20a%20Sept.jpg?alt=media&token=c9491b20-c360-4683-8f76-904bc69dfd38",
			"featured": true,
			"name": "Cinq a Sept",
			"order": 6,
			"id": "wRoJMVGUI5yRjV5tRjKH"
		},
		{
			"name": "Coach",
			"id": "HAv42M7R6KxIQYgLDPaT"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568566862326-DKNY-Size-Chart-1.jpg?alt=media&token=9151dafc-d3b3-4135-a083-e5c7626eaa5d",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568566872708-DKNY-Size-Chart-1.jpg?alt=media&token=85800289-ec09-4194-8428-5b5f8166c541",
			"name": "DKNY",
			"id": "U87cIVEljmbqwRQ9DiYu"
		},
		{
			"name": "Dolce & Gabbana",
			"id": "E58h3s9cJGp5sZ5sBSLz"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/featured_designers%2F1.%20Dress%20the%20population.jpg?alt=media&token=bd7112df-23ec-4b98-97c6-6961b8826691",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568566971913-Dress-the-Population-Sizing-Chart-.jpg?alt=media&token=19977aed-5a70-4cc6-9d79-d13ff6956040",
			"featured": true,
			"name": "Dress The Population",
			"order": 1,
			"id": "SXMHKnap5eAVf7d5Wucv"
		},
		{
			"name": "Fendi",
			"id": "jmLwmkqxQJQWKXOkPGsa"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/scaled_images%2Ffor_love_and_lemons.png?alt=media&token=f860b7f9-7bf1-4425-89a1-4c089529c174",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568567002046-For-love-%26-Lemons-Size-Chart-1.jpg?alt=media&token=5cdbdea4-3143-4b94-b84c-c4bc259d2238",
			"featured": false,
			"name": "For Love & Lemons",
			"order": 8,
			"id": "9pQQCRZLHmLYBrghye90"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/scaled_images%2Ffree_people.png?alt=media&token=88668189-fb4d-4380-839f-52de1c7de1d0",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568567028341-Free-People-Sizing-chart.jpg?alt=media&token=57ea522a-4cbb-41b1-87f0-309c4a968b10",
			"featured": false,
			"name": "Free People",
			"order": 7,
			"id": "yAXYI4BehYRSZIdBJ2U8"
		},
		{
			"name": "Furla",
			"id": "bTaf1dOOys0mOtFT9lVV"
		},
		{
			"name": "Givenchy",
			"id": "Hhlk1Zs3ytVclPoC1EW7"
		},
		{
			"name": "Gucci",
			"id": "IicCN8unCPliI4UMyF1W"
		},
		{
			"name": "Hugo Boss",
			"id": "j11tM7873zsTfr4GrJcD"
		},
		{
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568567059040-J-crew-clothing-sizing-chart.jpg?alt=media&token=1b7d49fd-9e30-4a6c-b7be-5ee1b64f50e4",
			"name": "J crew",
			"id": "KYL5JTvWVdvmmpLdQd9U"
		},
		{
			"name": "J&M Davidson",
			"id": "uNdWujaKjcRBLjDNu1ku"
		},
		{
			"name": "Jimmy Choo",
			"id": "NehU75eOfNqPBxsiBBnc"
		},
		{
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568567108112-Karen-Millen-Size-Chart-1.jpg?alt=media&token=cd3a5679-f8c7-4605-a043-2191e07cd2ca",
			"name": "Karen Millen",
			"id": "q0uQtoraNRuYb3PR8C6p"
		},
		{
			"name": "Karl Lagerfeld",
			"id": "6BAMRCGF7po5uHOzSAXp"
		},
		{
			"name": "Kate Spade New York",
			"id": "rQRTtGG10q9O0OEdrIo3"
		},
		{
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1576266095325-Laura%20Bettini%20size%20chart%20.jpg?alt=media&token=a3b67010-7cb2-4c34-b00f-042c80129ac0",
			"name": "Laura Bettini",
			"id": "dBCdHAOBzE34XYFryx4Q"
		},
		{
			"name": "Loewe",
			"id": "xVHd6GQMcAaEmgjKfcU5"
		},
		{
			"name": "Longchamp Paris",
			"id": "ASyrXK90ucoj4253A0kU"
		},
		{
			"featured": false,
			"name": "Louis Vuitton",
			"order": 5,
			"id": "zAbwxDJ2W8nfEaobViWc"
		},
		{
			"name": "Maje",
			"id": "ZWHbF4gOFwOwMJwOtTJ2"
		},
		{
			"name": "Manu Atelier",
			"id": "u0ja3WvVQZ7EbV8XCAv5"
		},
		{
			"name": "Marc Jacobs",
			"id": "kNnXZypriP962lQRFLvw"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/scaled_images%2Fmk.png?alt=media&token=d334082c-5d73-4e6f-b391-e115aedfbed1",
			"featured": false,
			"name": "Michael Kors",
			"order": 6,
			"id": "UN9XyXoOgOzeptqqjCIp"
		},
		{
			"name": "Mo&co.",
			"id": "AXKNreVzwLzD2dLaLJ29"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/featured_designers%2F2.%20Nicole%20miller.jpg?alt=media&token=be314dc6-e157-4526-8dd4-99b78758d645",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568567143032-Karen-Millen-Size-Chart-1.jpg?alt=media&token=20c942cd-184f-4f90-8e9f-6af107ef3795",
			"featured": true,
			"name": "Nicole Miller",
			"order": 2,
			"id": "3APkHDul2dNR2rk5Oh9Z"
		},
		{
			"name": "ONLY",
			"id": "JG8ybmwFt0Hqfj8RAGJs"
		},
		{
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568567183911-Phase-Eight-Sizing-Chart.jpg?alt=media&token=bd671036-54cc-4c8f-8423-69c3c462db43",
			"name": "Phase Eight",
			"id": "LDD9WdPNQ5LFrfnld4Tr"
		},
		{
			"name": "Pleats Please Issey Miyake",
			"id": "lbCB7qtw1VziPy4VVBBf"
		},
		{
			"name": "Pleats Please",
			"id": "aJl6fRloX4zeoGw8aJhS"
		},
		{
			"name": "Prada",
			"id": "v2gK9pEcThGsNK3ngbg4"
		},
		{
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568567214611-Rachel-Roy-Size-Chart-1.jpg?alt=media&token=a07f84d4-694a-423c-b3f3-ed2112b4338a",
			"name": "Rachel Roy",
			"id": "2P94cc0dSSEFSg4n3pae"
		},
		{
			"name": "Ralph Lauren",
			"id": "yQ6F31JDV4u1SQ2uNHmv"
		},
		{
			"name": "Rebecca Taylor",
			"id": "Ky68LZjgj7jDlZ95Y8O2"
		},
		{
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568567266367-Red-Valentino-Size-Chart.jpg?alt=media&token=1b05cf11-c02c-462e-b987-d2c2295e206e",
			"name": "Red Valentino",
			"id": "8GVuM7sztbLfq8mBn2l3"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/featured_designers%2F8.%20Ronny%20Kobo.jpg?alt=media&token=d44a050a-64dd-412a-9071-6eb776cfe649",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1576265322072-Ronny%20Kobo%20SIze%20Chart.jpg?alt=media&token=f0b80a90-37f4-433f-bda8-e241039321bc",
			"featured": true,
			"name": "Ronny Kobo",
			"order": 8,
			"id": "WFKMIi5RERG8NjPmFEic"
		},
		{
			"name": "Salvatore Ferragamo",
			"id": "Q54eQ8yTeRHaAoGTYUKx"
		},
		{
			"name": "Sandro",
			"id": "if5SI1VFkGhkg0xGDg4L"
		},
		{
			"name": "Scandinavia",
			"id": "THjR0U9kczDr1VK2ecrv"
		},
		{
			"name": "See by Chloé",
			"id": "0qVJdq1iQXDc2cKsUGZC"
		},
		{
			"name": "Self-Portrait",
			"id": "Z6zrezUSjfvliaEkKP9b"
		},
		{
			"name": "Steve Madden",
			"id": "GSZ8k4xXWbofwTe2nyST"
		},
		{
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568567295787-Stevie-May-Sizing-Chart.jpg?alt=media&token=624f5e8c-2773-4777-a60b-bbcfd953fe68",
			"name": "Stevie May",
			"id": "IrGKg2BUN1koEnRsZazu"
		},
		{
			"name": "Swarovski",
			"id": "T1QnlV3zOLX100cgtr4b"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/featured_designers%2F7.%20Tadashi%20Shoji.jpg?alt=media&token=0db58627-9401-49b8-b4b6-da1538eea6c1",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568567334104-Tadashi-Shoji-Size-Chart-1.jpg?alt=media&token=4bb2e7b0-3fc2-4a87-b6bc-16f0689a4d63",
			"featured": true,
			"name": "Tadashi Shoji",
			"order": 7,
			"id": "cImTkK8CWIWO4v1GMTyO"
		},
		{
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1574105697967-Talbots%20Petite%20Size%20Chart.jpg?alt=media&token=d04d0697-0123-4e09-9f0c-97050b653d63",
			"name": "Talbots",
			"id": "qnlBVtBgDivfbn3JzWXt"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/scaled_images%2Ftalulah.png?alt=media&token=20b7f694-4413-4f4a-b9f2-3779ad7b9fa5",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1567700923312-Talulah%20Size%20Chart.jpg?alt=media&token=2ebee670-bd32-4426-bf42-ef0f9fdd0882",
			"featured": false,
			"name": "Talulah",
			"order": 4,
			"id": "JtVD6Ki0TgfbG773WajM"
		},
		{
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1570561872382-Ted%20Baker%20Size%20Chart%20.jpg?alt=media&token=40c6e8c7-4f5e-49c7-89ef-117f4e09a78e",
			"name": "Ted Baker ",
			"id": "lfbibatXFl9xrR3aABez"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/scaled_images%2Fthe_east_order.png?alt=media&token=35712c1d-cbce-4577-a6f9-501bbea1fbc7",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568567346107-The%20East%20Order%20Sizing%20Chart.jpg?alt=media&token=863f8417-afae-4fe8-8010-053ee55c9722",
			"featured": false,
			"name": "The East Order",
			"order": 3,
			"id": "hKAOOR8SCzLMjYPkulEm"
		},
		{
			"name": "Theory",
			"id": "W4jIOgFa7a7rNZsfI1se"
		},
		{
			"name": "Tory Burch",
			"id": "cIJB2Kb1TLcr0Wg090cu"
		},
		{
			"name": "Two Pears",
			"id": "OqiocYWRlK3qVsYv5DJS"
		},
		{
			"name": "Valentino by Mario Valentino",
			"id": "2e9HiBzt6f0fMXvQMxj0"
		},
		{
			"designer_image": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/scaled_images%2Fvicky_and_lucas.png?alt=media&token=cce809ad-8d4b-4e35-82c3-088e50828da2",
			"designer_sizes": "https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/images%2F1568567375987-Vicky-and-Lucas-General-Size-Chart.jpg?alt=media&token=47fb0d74-6639-4e0d-ae77-c477722d5f3b",
			"featured": false,
			"name": "Vicky and Lucas",
			"order": 5,
			"id": "eikasDODOPoUvg2C2caH"
		},
		{
			"name": "Weekend Max Mara",
			"id": "umewePWTzg1NGFiwnure"
		},
		{
			"name": "Y.A.S",
			"id": "rnOkwvjizhPbfO2qoG9d"
		},
		{
			"name": "Ym+",
			"id": "70E14od2sUZZy4ae4BdO"
		},
		{
			"name": "Zadig & Voltaire",
			"id": "132R9oTZHbCCpDI3bW0x"
		},
		{
			"name": "ba&sh",
			"id": "AOvQeWZ7TmsrQNiafWWh"
		}
		];
	}

	getByName() {
		let obj = {};
		this.designers.filter(row => {
			// remove '-' from e.g. nicole-miller
			let rName = row.name.replace(/\s/g, '-').toLowerCase();
			if (rName === this.name) {
				obj = row;
			}
			return null;
		});
		return obj;
	}

	getObjById() {
		let obj = {};
		this.designers.filter(row => {
			if (row.id === this.id) {
				obj = row;
			}
			return null;
		});
		return obj;
	}
}