const options = [
  {
	key: 'dashboard',
	label: 'Dashboard',
	leftIcon: 'ion-arrow-graph-up-right',
  },
  {
	key: 'settings',
	label: 'Settings',
	leftIcon: 'ion-ios-cog-outline',
  },
  {
	key: 'users',
	label: 'Users',
	leftIcon: 'ion-android-person',
  },
  {
	key: 'shop',
	label: 'Shop',
	leftIcon: 'ion-bag',
	children: [
	  {
		key: 'products',
		label: 'Products',
	  },
	  {
		key: 'orders',
		label: 'Orders',
	  },
	  {
		key: 'categories',
		label: 'Categories',
	  },
	  {
		key: 'designers',
		label: 'Designers',
	  },
	  {
		key: 'colors',
		label: 'Colors',
	  },
	  {
		key: 'sizes',
		label: 'Sizes',
	  },
	  {
		key: 'occasions',
		label: 'Occasions',
	  },
	  {
		key: 'formalities',
		label: 'Formalities',
	  }, {
		key: 'reviews',
		label: 'Reviews',
	  },
	],
  }
];
export default options;
