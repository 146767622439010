import React, {Component} from 'react';
import {connect} from 'react-redux';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import Auth0 from '../../helpers/auth0/index';
import IntlMessages from '../utility/intlMessages';
import SignUpStyleWrapper from './signup.style';
import {Button, Checkbox, Form, Icon, Input} from 'antd';
import {IntlProvider} from "react-intl";
import localeData from "../../locales/data.json";
import {LangContext} from "../../langContext";
import {FM} from "../../languageProvider/FMreactIntl";
import {notif} from "../../helpers/utility";

const {login, signupUser} = authAction;
const {clearMenu} = appActions;

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      lang: "en"
    };
  }

  holderCode() {
    return (<div className="isoInputWrapper isoOtherLogin">
      <Button
        onClick={this.handleLogin}
        type="primary"
        className="btnFacebook"
      >
        <IntlMessages id="page.signUpFacebook"/>
      </Button>
      <Button
        onClick={this.handleLogin}
        type="primary"
        className="btnGooglePlus"
      >
        <IntlMessages id="page.signUpGooglePlus"/>
      </Button>
      {Auth0.isValid && (
        <Button
          onClick={() => {
            Auth0.login();
          }}
          type="primary"
          className="btnAuthZero"
        >
          <IntlMessages id="page.signUpAuth0"/>
        </Button>
      )}
    </div>);
  }

  compareToFirstPassword = (rule, value, callback) => {
    const {form} = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(FM("Form.Password.Error3", "Password not match"));
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const {form} = this.props;
    if (value && value.length < 6) {
      callback(FM("Form.Password.Error2", "Password must be at least 6 characters!"));
    }
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], {force: true});
    }
    callback();
  };

  handleConfirmBlur = e => {
    const {value} = e.target;
    this.setState({confirmDirty: this.state.confirmDirty || !!value});
  };

  handleLogin() {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (!values.terms) {
          notif("error", "To finalize your registration, please read and agree to the Terms and Conditions of Beyond the Runway.", "Pour devenir membre de Beyond the Runway, vous devez lire et accepter les Modalités et Conditions.", this.context[0]);
        } else {
          values.sign_up_date = new Date().toString();
          let name = {fName: values.first_name, lName: values.last_name};
          this.subscribeToMailChimpList(name, values.email);
          this.props.signupUser(values);
        }
      } else {
        notif("error", "Please fill in all required fields.", "Veuillez remplir tous les champs requis.", this.context[0]);
      }
    });
  };

  subscribeToMailChimpList(name, email) {
    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.status === 200) {
        // console.log(this.responseText)
      }
    };
    let getUrl = "https://beyondtherunway.us20.list-manage.com/subscribe/post-json?u=3a97c41e6d25ef0feecd87f2c&id=e1688f57ee";
    let queryStrings = [`&MMERGE2=${name.fName}`, `&MMERGE3=${name.lName || ""}`, `&MMERGE4=btr-sign-up-form`];
    xhttp.open("GET", getUrl + queryStrings[0] + queryStrings[1] + queryStrings[2] + `&EMAIL=${email}&c=__jp0`, true);
    xhttp.send();
  }

  isCurrentPageSignUp() {
    let pathname = window.location.pathname;
     if(pathname === "/signup") {
       return <p className="new-account-wrapper">
         <a href='signin'>
           {FM("Menu.AccountExistsLoginNow", "Already have an account? Log in here.")}
         </a>
       </p>;
     } else {
       return <p className="new-account-wrapper">
         <a onClick={this.props.signin} href='#signin'>
           {FM("Menu.AccountExistsLoginNow", "Already have an account? Log in here.")}
         </a>
       </p>;
     }
  }
  static contextType = LangContext;

  render() {
    if (this.props.isLoggedIn) {
      window.location.href = window.location.origin.concat('/account');
    }
    let lang = this.context[0];
    const {getFieldDecorator} = this.props.form;
    return <IntlProvider
        locale={lang}
        messages={localeData[lang]}
        defaultLocale="en">
      <SignUpStyleWrapper className="isoSignUpPage" id="top">
        <div className="isoSignUpContentWrapper">
          <div className="isoSignUpContent" style={{paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px'}}>
            <div className="isoSignUpForm">
              <Form className="isoSignUp-Form" onSubmit={this.handleLogin}>
                <div className="isoInputWrapper isoLeftRightComponent">
                  <Form.Item hasFeedback>
                    {getFieldDecorator('first_name', {
                      rules: [{
                        required: true,
                        message: FM("Form.FirstName.Error", "Invalid first name!"),
                        whitespace: true,
                      }],
                    })(<Input allowClear size="large" placeholder="First name / Prénom"/>)}
                  </Form.Item>
                </div>
                <div className="isoInputWrapper isoLeftRightComponent">
                  <Form.Item hasFeedback>
                    {getFieldDecorator('last_name', {
                      rules: [{
                        required: true,
                        message: FM("Form.LastName.Error", "Invalid last name!"),
                        whitespace: true,
                      }],
                    })(<Input allowClear size="large" placeholder="Last name / Nom de famille"/>)}
                  </Form.Item>
                </div>
                <div className="isoInputWrapper isoLeftRightComponent">
                  <Form.Item hasFeedback>
                    {getFieldDecorator('email', {
                      rules: [{
                        type: 'email',
                        required: true,
                        message: FM("Form.Email.Error", "Invalid email address!")
                      }],
                    })(<Input allowClear prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Email"/>,)}
                  </Form.Item>
                </div>
                <div className="isoInputWrapper isoLeftRightComponent">
                  <Form.Item>
                    {getFieldDecorator('password', {
                      rules: [{
                        required: true,
                        message: FM("Form.Password.Error", "Invalid password!")
                      },
                        {validator: this.validateToNextPassword,},],
                    })(<Input.Password allowClear prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Password / Mot de passe"/>,)}
                  </Form.Item>
                </div>
                <div className="isoInputWrapper isoLeftRightComponent">
                  <Form.Item>
                    {getFieldDecorator('confirm', {
                      rules: [{
                        required: true,
                        message: FM("Form.Password.Error", "Invalid password!")
                      },
                        {validator: this.compareToFirstPassword,},],
                    })(<Input.Password allowClear prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Confirm password / Confirmer le mot de passe" onBlur={this.handleConfirmBlur}/>)}
                  </Form.Item>
                </div>
                <div className="isoInputWrapper" style={{marginBottom: '25px'}}>
                  {getFieldDecorator('terms', {
                    valuePropName: 'checked',
                    initialValue: false,
                  })(<Checkbox>{FM("Text.Agree.With", "I agree with the")}
                  <a href='/terms-conditions' target='_blank'>
                    {FM("Menu.Terms.Conditions", "Terms and Conditions")}
                  </a> of Beyond the Runway.</Checkbox>)}
                </div>
              </Form>
              <div className="isoInputWrapper">
                <Button type="primary" className="ant-btn btr-black-btn" style={{marginTop: '0px'}} onClick={this.handleLogin.bind(this)}>
                  <span style={{textTransform: "uppercase"}}>
                    {FM("Text.SignUp", "Sign Up")}
                  </span>
                </Button>
              </div>
              <div className="isoInputWrapper isoCenterComponent isoHelperWrapper">
                {this.isCurrentPageSignUp()}
              </div>
            </div>
          </div>
        </div>
      </SignUpStyleWrapper>
    </IntlProvider>;
  }
}

const SignUp = Form.create({name: 'signup_form'})(SignUpForm);
export default connect(
  state => ({
    isLoggedIn: state.Auth.idToken !== null,
  }),
  {login, clearMenu, signupUser}
)(SignUp);
