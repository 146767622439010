import actions from './actions';

const initState = {
  products: [],
  product: null,
  designers: [],
  formalities: [],
  occasions: [],
  fabrics: [],
  colors: [],
  sizes: [],
  missingBulk: [],
  categories: [],
  users: [],
  user: null,
  orders: [],
  order: {},
  subscriptions: [],
  settings: {},
  reviews: [],
  review: null
};

export default function shopReducer(state = initState, action) {
  switch (action.type) {
  case actions.RECEIVED_ADMIN_PRODUCTS:
	return {...state, products: action.payload};
  case actions.RECEIVED_ADMIN_PRODUCT:
	return {...state, product: action.payload};
  case actions.RECEIVED_ADMIN_DESIGNERS:
	return {...state, designers: action.payload};
  case actions.RECEIVED_ADMIN_FORMALITIES:
	return {...state, formalities: action.payload};
  case actions.RECEIVED_ADMIN_OCCASIONS:
	return {...state, occasions: action.payload};
  case actions.RECEIVED_ADMIN_FABRICS:
	return {...state, fabrics: action.payload};
  case actions.RECEIVED_ADMIN_COLORS:
	return {...state, colors: action.payload};
  case actions.RECEIVED_ADMIN_CATEGORIES:
	return {...state, categories: action.payload};
  case actions.RECEIVED_ADMIN_SIZES:
	return {...state, sizes: action.payload};
  case actions.RECEIVED_ADMIN_COLOR:
	var colors = [...state.colors, action.payload];
	return {...state, colors};
  case actions.RECEIVED_ADMIN_CATEGORY:
	var categories = [...state.categories, action.payload];
	return {...state, categories};
  case actions.RECEIVED_ADMIN_FABRIC:
	var fabrics = [...state.fabrics, action.payload];
	return {...state, fabrics};
  case actions.RECEIVED_ADMIN_OCCASION:
	var occasions = [...state.occasions, action.payload];
	return {...state, occasions};
  case actions.RECEIVED_ADMIN_FORMALITY:
	var formalities = [...state.formalities, action.payload];
	return {...state, formalities};
  case actions.RECEIVED_ADMIN_SIZE:
	var sizes = [...state.sizes, action.payload];
	return {...state, sizes};
  case actions.RECEIVED_ADMIN_DESIGNER:
	var designers = [...state.designers, action.payload];
	return {...state, designers};
  case actions.RECEIVED_MISSING_BULK:
	return {...state, missingBulk: action.payload};
  case actions.RECEIVED_ADMIN_USERS:
	return {...state, users: action.payload};
  case actions.RECEIVED_ADMIN_USER:
	return {...state, user: action.payload};
  case actions.RECEIVED_ADMIN_ORDERS:
	return {...state, orders: action.payload};
  case actions.RECEIVED_ADMIN_ORDER:
	return {...state, order: action.payload};
  case actions.RECEIVED_ADMIN_USER_SUBSCRIPTIONS:
	return {...state, subscriptions: action.payload};
  case actions.RECEIVED_APP_SETTINGS:
	return {...state, settings: action.payload};
  case actions.RECEIVED_ADMIN_REVIEWS:
	return {...state, reviews: action.payload};
  case actions.RECEIVED_ADMIN_REVIEW:
	return {...state, review: action.payload};
  default:
	return state;
  }
}
