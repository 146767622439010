import Auth from './auth/reducer';
import App from './app/reducer';
import Admin from './admin/reducers';
import Ecommerce from './ecommerce/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import Store from './store/reducer';

export default {
  Auth,
  App,
  Admin,
  ThemeSwitcher,
  Ecommerce,
  Store
};
