import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  .shopping-bag-cart {
    width: 20px;
    height: 20px;
    border-radius: 0;
  }
  .btr-black-btn {
	border-radius:0;
	color:#fff;
	background:#000; 
	min-width:200px;
	display:block;
	margin-top:25px;
	height:50px !important;
	line-height:50px !important;
	padding:0;
	width:100%;
	text-transform: uppercase;
	letter-spacing: 2px;
  }
  .btr-black-btn:hover{
	border: #000 1px solid;
	color: #fff;
	opacity: .8;
	background:#000;
  }
  .btr-black-btn:active{
    background: #fff !important;
    color: #000 !important;
    border-color: #ddd !important;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .anticon:not(.anticon-question-circle) {
	 margin-right: 0 !important;
  }
  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }
  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }
  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;
        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;
          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;
            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }
          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }
          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }
body{
    #item_0$Menu{
        background-color:red;
    }
}
html {
  overflow-y: hidden;
}
`;

export default GlobalStyles;
