import FirebaseHelper from '../../../../helpers/firebase';
import firebase from 'firebase';
import {capitalizeFirstLetter} from "../../../../helpers/utility";

const db = FirebaseHelper.database;
const storage = FirebaseHelper.storage;

const occasionsRef = db.collection('occasions');
const sizesRef = db.collection('sizes');
const colorsRef = db.collection('colors');
const designersRef = db.collection('designers');
const formalitiesRef = db.collection('formality');
const categoriesRef = db.collection('categories');
const productsRef = db.collection('products');
const fabricsRef = db.collection('fabrics');
const reviewsRef = db.collection('reviews');
const appSettingsRef = db.collection('appSettings');
const usersRef = db.collection('users');
const ordersRef = db.collection('orders');

class ProductsHelper {
///////////////////////////////////////////////////////////////////////////////////////
/// DELETE
///////////////////////////////////////////////////////////////////////////////////////
  deleteOccasion = async payload => {
	return await occasionsRef.doc(payload).delete().then(function () {
	  return {'successful': true};
	}).catch(function (error) {
	  return error;
	});
  };
  deleteSize = async payload => {
	return await sizesRef.doc(payload).delete().then(function () {
	  return {'successful': true};
	}).catch(function (error) {
	  return error;
	});
  };
  deleteColor = async payload => {
	return await colorsRef.doc(payload).delete().then(function () {
	  return {'successful': true};
	}).catch(function (error) {
	  return error;
	});
  };
  deleteDesigner = async payload => {
	return await designersRef.doc(payload).delete().then(function () {
	  return {'successful': true};
	}).catch(function (error) {
	  return error;
	});
  };
  deleteFormality = async payload => {
	return await formalitiesRef.doc(payload).delete().then(function () {
	  return {'successful': true};
	}).catch(function (error) {
	  return error;
	});
  };
  deleteCategories = async payload => {
	return await categoriesRef.doc(payload).delete().then(function () {
	  return {'successful': true};
	}).catch(function (error) {
	  return error;
	});
  };
  deleteProduct = async payload => {
	return await productsRef.doc(payload).delete().then(function () {
	  return {'successful': true};
	}).catch(function (error) {
	  return error;
	});
  };
///////////////////////////////////////////////////////////////////////////////////////
/// GET|FETCH
///////////////////////////////////////////////////////////////////////////////////////
  getAppSettings = async () => {
    return await appSettingsRef.doc('app_settings').get().then(function (doc) {
      if (doc.exists) {
        return doc.data();
      } else {
        return {'error': "No settings found"};
      }
    }).catch(function (error) {
      return error;
    });
  };
  getProducts = async payload => {
	return await productsRef.get().then(function (querySnapshot) {
      const data = [];
      querySnapshot.forEach(function (doc) {
		const product = doc.data();
		product.id = doc.id;
		data.push(product);
	  });
	  return data;
	}).catch(function (error) {
	  return error;
	});
  };
  getDesigners = async payload => {
	return await designersRef.orderBy("name", "asc").get().then(function (querySnapshot) {
      const data = [];
      querySnapshot.forEach(function (doc) {
		const designer = doc.data();
		designer.id = doc.id;
		data.push(designer);
	  });
	  return data;
	}).catch(function (error) {
	  return error;
	});
  };
  getReviews = async payload => {
	return await reviewsRef.get().then(function (querySnapshot) {
	  const data = [];
	  querySnapshot.forEach(function (doc) {
		const size = doc.data();
		size.id = doc.id;
		data.push(size);
	  });
	  return data;
	}).catch(function (error) {
	  return error;
	});
  };
  getReview = async payload => {
	return await reviewsRef.doc(payload).get().then(function (doc) {
	  if (doc.exists) {
		const product = doc.data();
		product.id = doc.id;
		return product;
	  } else {
		// doc.data() will be undefined in this case
		return {'error': "No review found"};
	  }
	}).catch(function (error) {
	  return error;
	});
  };
  getUsers = async payload => {
    return await usersRef.get().then(function (querySnapshot) {
      const data = [];
      querySnapshot.forEach(function (doc) {
        const user = doc.data();
        user.id = doc.id;
        data.push(user);
        data.sort(function (a, b) {
          return new Date(b.sign_up_date) - new Date(a.sign_up_date);
        });
      });
      return data;
    }).catch(function (error) {
      return error;
    });
  };
  getUser = async payload => {
    return await usersRef.doc(payload).get().then(function (doc) {
      if (doc.exists) {
        const user = doc.data();
        user.id = doc.id;
        return user;
      } else {
        return ({error: 'no user found'});
      }
    }).catch(function (error) {
      return error;
    });
  };
  // used in admin dashboard
  getOrders = async payload => {
    return await ordersRef.orderBy("createdAt", "desc").get().then(function (querySnapshot) {
      const data = [];
      querySnapshot.forEach(function (doc) {
        const order = doc.data();
        order.id = doc.id;
		data.push(order);
      });
      return data;
    }).catch(function (error) {
      return error;
    });
  };
///////////////////////////////////////////////////////////////////////////////////////
/// ADD|CREATE
///////////////////////////////////////////////////////////////////////////////////////
  saveAdminReview = async payload => {
	return await reviewsRef.doc(payload.id).update(payload)
		.then(function () {
		  return payload;
		})
		.catch(function (error) {
		  return {error: "Something went wrong. Please try again"};
		});
  };
  addColor = async payload => {
	return await colorsRef.add(payload)
		.then(function (docRef) {
		  payload.id = docRef.id;
		  return payload;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addFabric = async payload => {
	return await fabricsRef.add(payload)
		.then(function (docRef) {
		  payload.id = docRef.id;
		  return payload;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addOccasion = async payload => {
	return await occasionsRef.add(payload)
		.then(function (docRef) {
		  payload.id = docRef.id;
		  return payload;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addFormality = async payload => {
	return await formalitiesRef.add(payload)
		.then(function (docRef) {
		  payload.id = docRef.id;
		  return payload;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addSize = async payload => {
	return await sizesRef.add(payload)
		.then(function (docRef) {
		  payload.id = docRef.id;
		  return payload;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addDesigner = async payload => {
	return await designersRef.add(payload)
		.then(function (docRef) {
		  payload.id = docRef.id;
		  return payload;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addCategory = async payload => {
	return await categoriesRef.add(payload)
		.then(function (docRef) {
		  payload.id = docRef.id;
		  console.log(payload);
		  return payload;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addNewProduct = async payload => {
	return await productsRef.add(payload)
		.then(function (docRef) {
		  payload.id = docRef.id;
		  return payload;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addProductCat = async payload => {
	const name = (+new Date()) + '-' + payload.file.name;
	const dataRef = storage.ref('images').child(name);
	return await dataRef.put(payload.file).then(snapshot => snapshot.ref.getDownloadURL())
		.then((url) => {
		  const productRef = categoriesRef.doc(payload.id);
		  productRef.update({
			"cat_image": url,
		  });
		  return url;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addProductDesigner = async payload => {
	const name = (+new Date()) + '-' + payload.file.name;
	const dataRef = storage.ref('images').child(name);
	return await dataRef.put(payload.file).then(snapshot => snapshot.ref.getDownloadURL())
		.then((url) => {
		  const productRef = designersRef.doc(payload.id);
		  productRef.update({
			"designer_image": url,
		  });
		  return url;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addProductImage = async payload => {
	const name = (+new Date()) + '-' + payload.file.name;
	const dataRef = storage.ref('images').child(name);
	return await dataRef.put(payload.file).then(snapshot => snapshot.ref.getDownloadURL())
		.then((url) => {
		  return url;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addTestimonialImage = async payload => {
	const name = (+new Date()) + '-' + payload.file.name;
	const dataRef = storage.ref('testimonial_images').child(name);
	return await dataRef.put(payload.file).then(snapshot => snapshot.ref.getDownloadURL())
		.then((url) => {
		  return url;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addSizeDesigner = async payload => {
	const name = (+new Date()) + '-' + payload.file.name;
	const dataRef = storage.ref('images').child(name);
	return await dataRef.put(payload.file).then(snapshot => snapshot.ref.getDownloadURL())
		.then((url) => {
		  const productRef = designersRef.doc(payload.id);
		  productRef.update({
			"designer_sizes": url,
		  });
		  return url;
		})
		.catch(function (error) {
		  return error;
		});
  };
  addBulkProducts = async payload => {
    var colors = [];
    var colorsAll = [];
    var designers = [];
    var designersAll = [];
    var fabrics = [];
    var fabricsAll = [];
    var formality = [];
    var formalitysAll = [];
    var occasions = [];
    var occasionsAll = [];
    var sizes = [];
    var sizesAll = [];
    var missingInfo = [];
    var mainCategories = [];
    var mainCategoriesAll = [];
    var productsAll = [];

    // Capture all export categories array items
    payload.forEach((product) => {
      product.color.forEach((col) => {
        var co = col.toLowerCase().trim();
        if (!colors.includes(co)) {
          colors.push(co);
        }
      });
      var category = product.mcategory ? product.mcategory.toLowerCase().trim() : '';
      if (!mainCategories.includes(category)) {
        if (category.length > 0) {
          mainCategories.push(category);
        }
      }

      var designer = product.designer ? product.designer.toLowerCase().trim() : '';
      if (!designers.includes(designer)) {
        if (designer.length > 0) {
          designers.push(designer);
        }
      }

      product.fabric.forEach((fab) => {
        var fa = fab.toLowerCase().trim();
        if (!fabrics.includes(fa)) {
          fabrics.push(fa);
        }
      });

      product.formality.forEach((item) => {
        var i = item.toLowerCase().trim();
        if (!formality.includes(i)) {
          formality.push(i);
        }
      });

      product.occasion.forEach((item) => {
        var i = item.toLowerCase().trim();
        if (!occasions.includes(i)) {
          occasions.push(i);
        }
      });

      product.size.forEach((item) => {
        var i = item.toLowerCase().trim();
        if (!sizes.includes(i)) {
          sizes.push(i);
        }
      });

    });
    //Start Import get all current products to check if exist
    return await productsRef.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        var pr = doc.data();
        pr.id = doc.id;
        productsAll.push(pr);
      });

      return Promise.all([
        colorsRef.get()
            .then(function (querySnapshot) {
              querySnapshot.forEach(function (doc) {
                const color = doc.data();
                color.id = doc.id;
                colorsAll.push(color);
              });
            }),
        designersRef.get()
            .then(docRefs => {
              docRefs.forEach(function (doc) {
                const designer = doc.data();
                designer.id = doc.id;
                designersAll.push(designer);
              });
            }),
        fabricsRef.get()
            .then(docRefs => {
              docRefs.forEach(function (doc) {
                const fabric = doc.data();
                fabric.id = doc.id;
                fabricsAll.push(fabric);
              });
            }),
        formalitiesRef.get()
            .then(docRefs => {
              docRefs.forEach(function (doc) {
                const item = doc.data();
                item.id = doc.id;
                formalitysAll.push(item);
              });
            }),
        occasionsRef.get()
            .then(docRefs => {
              docRefs.forEach(function (doc) {
                const item = doc.data();
                item.id = doc.id;
                occasionsAll.push(item);
              });
            }),
        sizesRef.get()
            .then(docRefs => {
              docRefs.forEach(function (doc) {
                const item = doc.data();
                item.id = doc.id;
                sizesAll.push(item);
              });
            }),
        categoriesRef.get()
            .then(docRefs => {
              docRefs.forEach(function (doc) {
                const item = doc.data();
                item.id = doc.id;
                mainCategoriesAll.push(item);
              });
            })
      ]);
    }).then(async result => {
		const filteredCategories = mainCategories.filter((item) => {
			return !compare(mainCategoriesAll, item);
		});
		//Add New Main Category
		let catAdd = async (c) => await categoriesRef.add(c).then(function (docRef) {
			c.id = docRef.id;
			mainCategoriesAll.push(c);
		});
		for (const col of filteredCategories) {
			if (col.length > 1) {
				await catAdd({'name': capitalizeFirstLetter(col)});
			}
		}

		//Add New Colors
		const filteredColours = colors.filter((item) => {
			return !compare(colorsAll, item);
		});
		let colorAdd = async (c) => await colorsRef.add(c).then(function (docRef) {
			c.id = docRef.id;
			colorsAll.push(c);
		});
		for (const col of filteredColours) {
			if (col.length > 1) {
				await colorAdd({'name': capitalizeFirstLetter(col)})
			}
		}

		//Add New Designers
		const filteredDesigners = designers.filter((item) => {
			return !compare(designersAll, item);
		});
		let designerAdd = async (c) => await designersRef.add(c).then(function (docRef) {
			c.id = docRef.id;
			designersAll.push(c);
		});
		for (const col of filteredDesigners) {
			if (col.length > 1) {
				await designerAdd({'name': capitalizeFirstLetter(col)})
			}
		}

		//Add New Fabrics
		const filteredFabrics = fabrics.filter((item) => {
			return !compare(fabricsAll, item);
		});
		let fabricAdd = async (c) => await fabricsRef.add(c).then(function (docRef) {
			c.id = docRef.id;
			fabricsAll.push(c);
		});
		for (const col of filteredFabrics) {
			if (col.length > 1) {
				await fabricAdd({'name': capitalizeFirstLetter(col)});
			}
		}

		//Add New Formality
		const filteredFormality = formality.filter((item) => {
			return !compare(formalitysAll, item);
		});
		let formalityAdd = async (c) => await formalitiesRef.add(c).then(function (docRef) {
			c.id = docRef.id;
			formalitysAll.push(c);
		});
		for (const col of filteredFormality) {
			if (col.length > 1) {
				await formalityAdd({'name': capitalizeFirstLetter(col)});
			}
		}

		//Add New Occasion
		const filteredOccasions = occasions.filter((item) => {
			return !compare(occasionsAll, item);
		});
		let occasionAdd = async (c) => await occasionsRef.add(c).then(function (docRef) {
			c.id = docRef.id;
			occasionsAll.push(c);
		});
		for (const col of filteredOccasions) {
			if (col.length > 1) {
				await occasionAdd({'name': capitalizeFirstLetter(col)})
			}
		}

		//Add New Sizes
		const filteredSizes = sizes.filter((item) => {
			return !compare(sizesAll, item);
		});
		let sizeAdd = async (c) => await sizesRef.add(c).then(function (docRef) {
			c.id = docRef.id;
			sizesAll.push(c);
		});
		for (const col of filteredSizes) {
			if (col.length > 1) {
				await sizeAdd({'name': capitalizeFirstLetter(col)});
			}
		}
      return Promise.resolve();
    }).then(async results => {
      //Add New Subcategories
      for (const product of payload) {
        var mainCat = null;
        var cat = product.mcategory ? product.mcategory.toLowerCase().trim() : '';
        // eslint-disable-next-line
        let mc = mainCategoriesAll.find(col => col.name.toLowerCase() === cat);
        if (mc) {
          mainCat = mc.id;
        }
        if (mainCat) {
          var subcatories = [];
          // eslint-disable-next-line
          product.scategory.forEach((fab) => {
            var sc = fab.toLowerCase().trim();
            if (!subcatories.includes(sc)) {
              subcatories.push(sc);
            }
          });
          var fileteredSubCategories = subcatories.filter((item) => {
            return !compare(mainCategoriesAll, item);
          });
          for (const col of fileteredSubCategories) {
            if (col.length > 1) {
              var c = {'name': capitalizeFirstLetter(col), 'is_sub': true, 'parent_cat': mainCat};
              // eslint-disable-next-line
              await categoriesRef.add(c).then(function (docRef) {
                c.id = docRef.id;
                mainCategoriesAll.push(c);
              });
            }
          }
        }
      }
      return Promise.resolve();
    }).then(async results => {
      const dataRef = productsRef;
      var promises = [];
      for (const product of payload) {
        console.log(product);
        console.log(product.title);
        var variant = product.size[0].toLowerCase() !== 'one size';
        //Categories
        console.log('cat');
        const productCategories = [];
        var cat = product.mcategory ? product.mcategory.toLowerCase().trim() : '';
        // eslint-disable-next-line
        let mc = mainCategoriesAll.find(col => col.name.toLowerCase() === cat);
        if (mc) {
          productCategories.push(mc.id);
          product.scategory.forEach((item) => {
            item = item.toLowerCase().trim();
            let sc = mainCategoriesAll.find(o => o.name.toLowerCase() === item);
            if (sc) {
              productCategories.push(sc.id);
            }
          });
        }
        console.log('fab');
        //Fabric
        const productFabrics = [];
        product.fabric.forEach((fabric) => {
          fabric = fabric.toLowerCase().trim();
          let f = fabricsAll.find(fab => fab.name.toLowerCase() === fabric);
          if (f) {
            productFabrics.push(f.id);
          }
        });
        console.log('color');
        //Colors
        var productColor = "";
        if (product.color.length > 0) {
          var color = product.color[0].toLowerCase().trim();
          // eslint-disable-next-line
          let c = colorsAll.find(col => col.name.toLowerCase() === color);
          if (c) {
            productColor = c.id;
          }
        }
        console.log('tags');
        //Colors Tags
        const colorTags = [];
        product.color.forEach((item) => {
          item = item.toLowerCase().trim();
          let f = colorsAll.find(o => o.name.toLowerCase() === item);
          if (f) {
            colorTags.push(f.id);
          }
        });
        console.log('designer');
        //Designers
        var productDesigner = "";
        var designer = product.designer ? product.designer.toLowerCase().trim() : '';
        // eslint-disable-next-line
        let d = designersAll.find(col => col.name.toLowerCase() === designer);
        if (d) {
          productDesigner = d.id;
        }
        console.log('occasions');
        //Occasions
        const productOccasions = [];
        product.occasion.forEach((item) => {
          item = item.toLowerCase().trim();
          let f = occasionsAll.find(o => o.name.toLowerCase() === item);
          if (f) {
            productOccasions.push(f.id);
          }
        });
        console.log('foormality');
        //Formality
        const productFormality = [];
        product.formality.forEach((item) => {
          item = item.toLowerCase().trim();
          let f = formalitysAll.find(o => o.name.toLowerCase() === item);
          if (f) {
            productFormality.push(f.id);
          }
        });
        const variables = [];
        var size = "";
        console.log('variant');
        console.log('color ' + productColor);
        if (variant) {
          console.log('is variant');
          var index = 0;
          // eslint-disable-next-line
          product.size.forEach((item) => {
            item = item.toLowerCase().trim();
            let f = sizesAll.find(o => o.name.toLowerCase() === item);
            if (f) {
              if (product.quantity[index]) {
                console.log('found size');
                const v = {
                  size: f.id,
                  color: productColor,
                  quantity: Number(product.quantity[index].trim())
                };
                variables.push(v);
              }
            }
            index++;
          });
        } else {
          console.log('not variant');
          var sizeString = product.size[0].toLowerCase();
          // eslint-disable-next-line
          let s = sizesAll.find(col => col.name.toLowerCase() === sizeString);
          if (s) {
            size = s.id;
          }
        }
        console.log('color ' + productColor);
        console.log(productColor.length);
        console.log(productColor);
        if (productColor.length > 1 && product.size.length !== 0 && product.quantity.length !== 0) {
          //Product Object NEED COLOR AND SIZE
          console.log('add ' + product.title);
          let i = productsAll.find(col => col.sku === product.style);
          if (i) {
            console.log('Found');
            console.log(i);
            if (i.is_variant === false) {
              console.log(product.title);
              const p = await dataRef.add({
                'status': 'active',
                "name": product.title,
                "categories": productCategories,
                "description": product.description ? product.description : '',
                "is_variant": variant,
                "price": Number(product.price),
                "quantity": variant ? 0 : Number(product.quantity[0]),
                "sku": product.style,
                "sizefit": product.sizefit ? product.sizefit : '',
                "fabrics": productFabrics,
                "color": productColor,
                "color_tags": colorTags,
                "occasions": productOccasions,
                "formalities": productFormality,
                // "fabrics" : productFabrics,
                "variants": variables,
                "size": size,
                "designer": productDesigner
              }).then(function (docRef) {
                var pr = {id: docRef.id, variants: variables, sku: product.style};
                productsAll.push(pr);
              }).catch(function (err) {
                console.log('A promise failed to resolve', err);
              });
              promises.push(p);
            } else {
              console.log('Found starting');
              variables.forEach((v) => {
                i.variants.push(v);
              });
              console.log(i.variants);
              i.color_tags.forEach((c) => {
                if (!colorTags.includes(c)) {
                  colorTags.push(c);
                }
              });
              console.log(colorTags);
              const p = await dataRef.doc(i.id).update({
                variants: i.variants,
                color_tags: colorTags
              }).catch(function (err) {
                console.log('A promise failed to resolve', err);
              });
              promises.push(p);
            }
          } else {
            console.log('start new add');
            
            const p = await dataRef.add({
              'status': 'draft',
              "name": product.title,
              "categories": productCategories,
              "description": product.description ? product.description : '',
              "is_variant": variant,
              "price": Number(product.price),
              "quantity": variant ? 0 : Number(product.quantity[0]),
              "sku": product.style,
              "sizefit": product.sizefit ? product.sizefit : '',
              // "fabrics" : productFabrics,
              "color": productColor,
              "color_tags": colorTags,
              "occasions": productOccasions,
              "formalities": productFormality,
              "fabrics": productFabrics,
              "variants": variables,
              "size": size,
              "designer": productDesigner
              // eslint-disable-next-line
            }).then(function (dRef) {
              console.log(dRef.id);
              var prod = {
                id: dRef.id,
                variants: variables,
                sku: product.style,
                is_variant: variant,
                color_tags: colorTags
              };
              console.log(prod);
              productsAll.push(prod);
              console.log(productsAll);
            }).catch(function (err) {
              console.log('A promise failed to resolve', err);
            });
            promises.push(p);
          }
        } else {
          console.log('missing');
          missingInfo.push(product);
        }
      }
      console.log('Completed Success');
      return Promise.all(promises).catch(function (err) {
        console.log('A promise failed to resolve', err);
        return err;
      });
    }).then(function (docRefs) {
      console.log(missingInfo);
      return missingInfo;
    }).catch(function (error) {
      return error;
    });
  };
///////////////////////////////////////////////////////////////////////////////////////
/// UPDATE
///////////////////////////////////////////////////////////////////////////////////////
  updateAppSettings = async payload => {
    const dataRef = appSettingsRef.doc('app_settings');
    return await dataRef.set(payload, {'merge': true})
        .then(function () {
          return payload;
        }).catch(function (error) {
          console.log(error);
          return {'error': "Error Occured updating product"};
        });
  };
  updateProduct = async payload => {
    const dataRef = productsRef.doc(payload.id);
    return await dataRef.update(payload)
        .then(function () {
          // console.log("updateProduct payload", payload);
          return payload;
        }).catch(function (error) {
          return {'error': "Error Occured updating product"};
        });
  };
  updateProductImage = async payload => {
    const name = (+new Date()) + '-' + payload.file.name;
    const dataRef = storage.ref('images').child(name);
    return await dataRef.put(payload.file).then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          const productRef = productsRef.doc(payload.id);
          const ref = productRef.update({
            "product_image": url,
          });
          console.log(ref);
          return url;
        })
        .catch(function (error) {
          return error;
        });
  };
  updateAdminCategory = async payload => {
	const dataRef = categoriesRef.doc(payload.id);
	return await dataRef.update({name: payload.name})
		.then(function () {
		  return payload;
		})
		.catch(function (error) {
		  console.error("Error updating document: ", error);
		  return {error: "Something went wrong. Please try again"};
		});
  };
  updateAdminDesigner = async payload => {
	const dataRef = designersRef.doc(payload.id);
	return await dataRef.update({name: payload.name})
		.then(function () {
		  return payload;
		})
		.catch(function (error) {
		  console.error("Error updating document: ", error);
		  return {error: "Something went wrong. Please try again"};
		});
  };
  updateAdminColor = async payload => {
	const dataRef = colorsRef.doc(payload.id);
	return await dataRef.update({name: payload.name})
		.then(function () {
		  return payload;
		})
		.catch(function (error) {
		  console.error("Error updating document: ", error);
		  return {error: "Something went wrong. Please try again"};
		});
  };
  updateAdminSize = async payload => {
	return await sizesRef.doc(payload.id).update({name: payload.name})
		.then(function () {
		  return payload;
		})
		.catch(function (error) {
		  console.error("Error updating document: ", error);
		  return {error: "Something went wrong. Please try again"};
		});
  };
  updateAdminOccasion = async payload => {
	const dataRef = occasionsRef.doc(payload.id);
	return await dataRef.update({name: payload.name})
		.then(function () {
		  return payload;
		})
		.catch(function (error) {
		  console.error("Error updating document: ", error);
		  return {error: "Something went wrong. Please try again"};
		});
  };
  updateAdminFormalities = async payload => {
	const dataRef = formalitiesRef.doc(payload.id);
	return await dataRef.update({name: payload.name})
		.then(function () {
		  return payload;
		})
		.catch(function (error) {
		  console.error("Error updating document: ", error);
		  return {error: "Something went wrong. Please try again"};
		});
  };
  updateAdminUser = async payload => {
    const dataRef = usersRef.doc(payload.id);
    return await dataRef.set(payload.user, {merge: true})
        .then(function () {
          return payload.user;
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
          return {error: "Something went wrong. Please try again"};
        });
  };
  updateOrder = async payload => {
	return await ordersRef.doc(payload.id).update(payload)
		.then(function () {
		  if (payload.status === "Returned" || payload.status === "Cancelled") {
			for (let i = 0; i < payload.products.length; i++) {
			  let spec = {
				qty: payload.products[i].quantity,
				size: payload.products[i].size,
				color: payload.products[i].color
			  };
			  productsRef.doc(payload.products[i].id).get().then(doc => {
				if (!doc.exists) {
				  console.log('No such product exists!');
				} else {
				  let originalProd = productsRef.doc(doc.data().id);
				  let totalQuantity = 0;
				  let variants = [];
				  for (let i = 0; i < doc.data().variants.length; i++) {
					let productVariants = doc.data().variants[i];
					if (productVariants.quantity === 0) {
					  productVariants.quantity = spec.qty;
					  variants.push(productVariants);
					  originalProd.update({variants: variants});
					} else {
					  if (productVariants.size === spec.size &&
						  productVariants.color === spec.color) {
						productVariants.quantity += spec.qty;
					  }
					  variants.push(productVariants);
					  originalProd.update({variants: variants});
					  totalQuantity += productVariants.quantity; // update total quantity
					}
				  }
				  let updateSingle = originalProd.update({quantity: totalQuantity});
				  return Promise.all([updateSingle]).then(res => {
				  });
				}
			  }).catch(err => {
				console.log('Error getting document', err);
			  });
			}
		  }
		  return payload;
		}).catch(function (error) {
		  console.error("Error updating document: ", error);
		  return {error: "Something went wrong. Please try again"};
		});
  };

  sendShippingEmail = async payload => {
	const sub = firebase.functions().httpsCallable('adminSendShippedEmail');
	return await sub({orderId: payload}).then(function (result) {
	  return result;
	}).catch(function (error) {
	  console.log(error);
	  return error;
	});
  };
}

function compare(ar, searchitem) {
  let found = false;
  for (let i = 0; i < ar.length; i++) {
	if (ar[i].name.toLowerCase() === searchitem) {
	  found = true;
	  break;
	}
  }
  return found;
}

export default new ProductsHelper();
