import firebase from 'firebase';
import FirebaseHelper from '../../../helpers/firebase';
import SearchUtil from "../../../helpers/searchHelper";
import dayjs from "dayjs";
import Fuse from "fuse.js"
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween)

const db = FirebaseHelper.database;

class StoreHelper {
	getCategories = async payload => {
		const dataRef = db.collection('categories');
		return await dataRef.orderBy("name", "asc").get().then(function (querySnapshot) {
			var data = [];
			querySnapshot.forEach(function (doc) {
				const cat = doc.data();
				cat.id = doc.id;
				data.push(cat);
			});
			return data;
		}).catch(function (error) {
			return error;
		});
	};
	getDesigners = async payload => {
		const dataRef = db.collection('designers');
		return await dataRef.orderBy("name", "asc").get().then(function (querySnapshot) {
			var data = [];
			querySnapshot.forEach(function (doc) {
				const d = doc.data();
				d.id = doc.id;
				data.push(d);
			});
			return data;
		}).catch(function (error) {
			return error;
		});
	};
	getOccasions = async payload => {
		const dataRef = db.collection('occasions');
		return await dataRef.orderBy("name", "asc").get().then(function (querySnapshot) {
			var data = [];
			querySnapshot.forEach(function (doc) {
				const d = doc.data();
				d.id = doc.id;
				data.push(d);
			});
			return data;
		}).catch(function (error) {
			return error;
		});
	};
	getFormalities = async payload => {
		return await db.collection('formality').orderBy("name", "asc").get().then(function (querySnapshot) {
			const data = [];
			querySnapshot.forEach(function (doc) {
				const d = doc.data();
				d.id = doc.id;
				data.push(d);
			});
			return data;
		}).catch(function (error) {
			return error;
		});
	};
	getColors = async () => {
		return await db.collection('colors').orderBy("name", "asc")
			.get().then(function (querySnapshot) {
				const data = [];
				querySnapshot.forEach(function (doc) {
					const d = doc.data();
					d.id = doc.id;
					data.push(d);
				});
				return data;
			}).catch(function (error) {
				return error;
			});
	};
	getSizes = async payload => {
		const dataRef = db.collection('sizes');
		return await dataRef.orderBy("name", "asc").get().then(function (querySnapshot) {
			var data = [];
			querySnapshot.forEach(function (doc) {
				const d = doc.data();
				d.id = doc.id;
				data.push(d);
			});
			return data;
		}).catch(function (error) {
			return error;
		});
	};
	getFabrics = async payload => {
		return await db.collection('fabrics').orderBy("name", "asc").get().then(function (querySnapshot) {
			const data = [];
			querySnapshot.forEach(function (doc) {
				const d = doc.data();
				d.id = doc.id;
				data.push(d);
			});
			return data;
		}).catch(function (error) {
			return error;
		});
	};
	getProduct = async payload => {
		return await db.collection('products').doc(payload).get().then(function (doc) {
			if (doc.exists) {
				const product = doc.data();
				product.id = doc.id;
				return product;
			} else {
				return { 'error': "No product found" };
			}
		}).catch(function (error) {
			return error;
		});
	};
	getProducts = async payload => {
		// console.log('printing payload => ', payload);
		const filteredJuniorSize = ["xaRtTZiWYDsLougtOs1E", "yoOAEje7XcNmRUWIf3dO", "wnuOMezffWaFK2tD0wMp", "2zLTANqq3anwgs0PJaLp", "7UNOQ77D359zmkLSeTlW", "cYhJjMmXLudZJqHtiO9Z", "7qFJeXUHD6GCkvOHVo1A"];

		let dataRef = db.collection('products');
		// SEARCH
		if (payload.search) {
			let designer = new SearchUtil().searchDesigner(payload.search, "name", payload.designersList);
			if (designer) {
				dataRef = dataRef.where("designer", "==", designer.id);
			}
			//let category = new SearchUtil().searchCategory(payload.search, "name", payload.categoriesList);
			if (Object.keys(payload).includes('cat')) {
				dataRef = dataRef.where("categories", "array-contains", payload.cat);
			}
			let color = new SearchUtil().searchColor(payload.search, "name", payload.colorsList);
			if (color) {
				dataRef = dataRef.where("color", "==", color.id);
			}
			let formality = new SearchUtil().searchFormality(payload.search, "name", payload.formalitiesList);
			if (formality) {
				dataRef = dataRef.where("formalities", "array-contains", formality.id);
			}
			let occasion = new SearchUtil().searchOccasion(payload.search, "name", payload.occasionsList);
			if (occasion) {
				dataRef = dataRef.where("occasions", "array-contains", occasion.id);
			}
		}
		/**
		 * categories and designers are required here
		 */
		// if (payload.cat) {
		// 	dataRef = dataRef.where("categories", "array-contains", payload.cat);
		// }

		if (payload.designer) {
			dataRef = dataRef.where("designer", "in", payload.designer);
		}
		return await dataRef.where("createdAt", "<", new Date()).limit(2000).get().then(function (querySnapshot) {
			const data = [];
			querySnapshot.forEach(function (doc) {
				const product = doc.data();
				product.id = doc.id;
				// console.log('product =>', product);
				// console.log(payload.cat)
				let add = true;
				if (payload.cat) {
					if (product.categories) {
						if (product.categories.filter((cat) => payload.cat.includes(cat)).length === 0) {
							add = false;
						}
					}
				}
				
				if (payload.formality) {
					let cond = false;
					for (let i = 0; i < product.formalities.length; i++) {
						if (payload.formality.includes(product.formalities[i])) {
							cond = true;
							break;
						}
					}
					if (!cond) {
						add = false;
					}
				}
				if (payload.color) {
					if (!payload.color.includes(product.color)) {
						if (payload.color.filter(value => product.color.includes(value)).length === 0) {
							add = false;
						}
					}
				}
				if (payload.size) {
					if (!product.variants.find(o => payload.size.includes(o.size))) {
						add = false;
					}
				}
				if (payload.arrival) {
					if (!product.createdAt) {
						return;
					}
					const productCreated = dayjs(product.createdAt.seconds * 1000);
					let isNew = productCreated.subtract(7, 'd');
					const today = dayjs();
					if (payload.arrival === 'month') {
						isNew = dayjs().subtract(24, 'M');
					}
					if (!dayjs(productCreated).isBetween(isNew, today, 'd')) {
						add = false;
					}
				}
				if (payload.junior) {
					let isJunior = false;
					product.variants.map((item) => {
						if (filteredJuniorSize.includes(item.size)) {
							isJunior = true;
						}
						return null;
					});
					if (!isJunior) {
						add = false;
					}
				}
				if (payload.occasion) {
					if (!payload.occasion.includes(product.occasions)) {
						if (payload.occasion.filter(value => product.occasions.includes(value)).length === 0) {
							add = false;
						}
					}
				}

				// SEARCH
				if (payload.search) {
					let addSearch = false;
					if (product.name.toLowerCase().indexOf(payload.search.toLowerCase()) > -1) {
						addSearch = true;
					}
					// check if description contains the search key, remove all html tags and &nbsp;
					const fuse = new Fuse([product
						.description
						.toLowerCase()], {
						includeScore: true,
						ignoreLocation: true
					})

					const results = fuse.search(payload.search.toLowerCase().trim())
					if (results.length > 0 && results[0].score < 0.66) {
						addSearch = true
					}
					const designer = payload.designersList.find(o => o.id === product.designer);
					if (designer) {
						if (designer.name.toLowerCase().indexOf(payload.search.toLowerCase()) > -1) {
							addSearch = true;
						}
					}
					if (product.sku.toLowerCase() === payload.search.toLowerCase()) {
						addSearch = true;
					}
					if ('categories' in product) {
						product['categories'].forEach((category) => {
							const cat = payload.categoriesList.find(o => o.id === category);
							if (cat) {
								if (cat['name'].toLowerCase().indexOf(payload.search.toLowerCase()) > -1) {
									addSearch = true;
								}
							}
						});
					}

					const formality = payload.formalitiesList.find(o => o.name.toLowerCase() === payload.search.toLowerCase());
					if (formality) {
						addSearch = true;
					}

					const color = payload.colorsList.find(o => o.name.toLowerCase() === payload.search.toLowerCase());
					if (color) {
						addSearch = true;
					}

					const occasion = payload.occasionsList.find(o => o.name.toLowerCase() === payload.search.toLowerCase());
					if (occasion) {
						addSearch = true;
					}
					add = addSearch;
				}
				if (add) {
					data.push(product);
				}
			});
			data.sort(function (x, y) {
				return x.featured === true ? -1 : y.featured === true ? 1 : 0;
			});
			data.slice(0, 40);
			// console.log(data);
			return data;
		}).catch(function (error) {
			return console.log({ error: error });
		});
	};
	getOrders = async payload => {
		const dataRef = db.collection('orders');
		return await dataRef.where("user", "==", payload)
			.orderBy("createdAt", "desc").get().then(function (querySnapshot) {
				var data = [];
				querySnapshot.forEach(function (doc) {
					const order = doc.data();
					order.id = doc.id;
					data.push(order);
				});
				return data;
			}).catch(function (error) {
				return error;
			});
	};
	getOrdersLimit = async payload => {
		const dataRef = db.collection('orders');
		return await dataRef.orderBy("createdAt", "desc")
			.limit(15).get().then(function (querySnapshot) {
				const data = [];
				querySnapshot.forEach(function (doc) {
					const order = doc.data();
					if ("products" in order && order.products.length > 0) {
						data.push(order.products[0]);
					}
				});
				return data;
			}).catch(function (error) {
				return error;
			});
	};
	submitReview = async payload => {
		const dataRef = db.collection('reviews');
		payload.createdAt = firebase.firestore.FieldValue.serverTimestamp();
		return await dataRef.add(payload)
			.then(function (docRef) {
				payload.id = docRef.id;
				return payload;
			})
			.catch(function (error) {
				return error;
			});
	};
	getOrder = async payload => {
		const dataRef = db.collection('orders').doc(payload);
		return await dataRef.get().then(function (doc) {
			if (doc.exists) {
				const order = doc.data();
				order.id = doc.id;
				return order;
			} else {
				return { error: "Order Not Found" };
			}
		}).catch(function (error) {
			return error;
		});
	};
	getProductReview = async payload => {
		const dataRef = db.collection('reviews');
		return await dataRef.where("product", "==", payload).where("status", "==", "published").orderBy("createdAt", "desc").get().then(function (querySnapshot) {
			var data = [];
			querySnapshot.forEach(function (doc) {
				const review = doc.data();
				console.log(review);
				review.id = doc.id;
				data.push(review);
			});
			return data;
		}).catch(function (error) {
			return error;
		});
	};
	getReviews = async payload => {
		const dataRef = db.collection('reviews');
		return await dataRef.where("user", "==", payload).orderBy("createdAt", "desc").get().then(function (querySnapshot) {
			var data = [];
			querySnapshot.forEach(function (doc) {
				const review = doc.data();
				console.log(review);
				review.id = doc.id;
				data.push(review);
			});
			return data;
		}).catch(function (error) {
			return error;
		});
	};
	getWishlist = async payload => {
		var products = [];
		for (const product of payload) {
			const dataRef = db.collection('products').doc(product);
			await dataRef.get().then(function (doc) {
				if (doc.exists) {
					const product = doc.data();
					product.id = doc.id;
					products.push(product);
				}
			});
		}
		return products;
	};
}


export default new StoreHelper();
