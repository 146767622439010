import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Modal } from "antd";
import appActions from "../../redux/app/actions";
import actions from "../../redux/store/actions";
import TopbarUser from "./topbarUserApp";
import TopbarAddtoCart from "./topbarAddtoCart";
import TopbarWrapper from "./topbarApp.style";
import MenuApp from './menuApp';
import Logo from '../../image/logo-btr.png';
import UserSidebar from '../../components/auth/userSideMenu';
import { Icon, Button, Input } from 'antd';
import { IntlProvider, FormattedMessage } from "react-intl";
import localeData from '../../locales/data.json';
import { LangContext } from "../../langContext";
import { FM } from "../../languageProvider/FMreactIntl";

const { Header } = Layout;
const { Search } = Input;
const { toggleCollapsed, changeLoginPopover, closeCollapsed } = appActions;
const { setFilters, getCategories, getDesigners, getOccasions, getFormalities, getColors, getSizes } = actions;

class Topbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			searchTerm: '',
			isFrench: false,
			locale: "en",
			searchModalVisible: false,
			cartDrawerVisible: false
		};
		this.sideMenuRef = React.createRef();
		this.toggleLocale = this.toggleLocale.bind(this);
		this.getStyling = this.getStyling.bind(this);
	}

	getStyling() {
		return {
			background: this.props.customizedTheme.backgroundColor,
			position: "fixed",
			width: "100%",
			height: this.props.view === "MobileView" ? 50 : 100
		};
	}

	onSelect = (value) => {
		if (value.length > 1) {
			this.updateFilter({ 'search': value });
		}
	};

	updateFilter(filter) {
		this.props.setFilters(filter);
		if (filter.cat === 'QyVssHHztPxP6fRwWCW4') {
			this.props.history.push('/shop/accessories');
		} else if (filter.cat === '5lpMnyLOBO6tSbzsSLPt') {
			this.props.history.push('/shop/dresses');
		} else {
			window.location.href = window.location.origin.concat(`/shop?q=${filter.search}`);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({ isFrench: nextProps.isFrench, locale: nextProps.locale });
	}

	componentDidMount() {
		if (this.props.categories) {
			this.props.getCategories();
		}
		if (this.props.designers) {
			this.props.getDesigners();
		}
		if (this.props.occasions) {
			this.props.getOccasions();
		}
		if (this.props.formalities) {
			this.props.getFormalities();
		}
		if (this.props.colors) {
			this.props.getColors();
		}
		if (this.props.sizes) {
			this.props.getSizes();
		}
	}

	toggleLocale() {
		this.setState({
			isFrench: !this.state.isFrench
		}, async () => await this.props.languageToggler(this.state.isFrench));
	}

	showDrawer = () => {
		this.props.changeLoginPopover();
	};

	showSearchMobile = () => {
		this.setState({ searchModalVisible: true });
	};

	handleOkSearchMobile = e => { };

	handleCancelSearchMobile = e => {
		this.setState({ searchModalVisible: false });
	};

	static contextType = LangContext;

	render() {
		const { toggleCollapsed, url, locale } = this.props;
		const collapsed = this.props.collapsed && !this.props.openDrawer;
		const { cartDrawerVisible } = this.state;
		let lang = this.context[0];
		let toggleLanguageElements = () => {
			return this.state.isFrench ? <Button onClick={this.toggleLocale}>EN</Button> : <Button onClick={this.toggleLocale}>FR</Button>
		};
		let menuAppElements = () => {
			return <MenuApp categories={this.props.categories}
				occasions={this.props.occasions}
				designers={this.props.designers}
				updateFilter={this.updateFilter.bind(this)}
				view={this.props.view}
				toggleCollapsed={toggleCollapsed}
				history={this.props.history} />;
		}
		let logoElements = () => {
			return <div className="isoLeft logo-wrapper">
				<a href={window.location.origin.concat('/')}>
					<img src={Logo} alt="Beyond the runway Logo" />
				</a>
			</div>;
		};
		return (
			<IntlProvider
				locale={lang}
				messages={localeData[lang]}
				defaultLocale="en">
				<TopbarWrapper>
					<Header style={this.getStyling()} className={collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"}>
						{this.props.view === "DesktopView" ? logoElements()
							:
							<div className="menu-mobile-wrapper">
								<div className="mobile-menu-btn mobile-trigger">
									<Icon type="menu" onClick={toggleCollapsed} className={
										collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
									} />
								</div>
								{logoElements()}
								<Icon type="search" style={{ fontSize: "20px", lineHeight: "12px", paddingLeft: "20px" }} onClick={this.showSearchMobile} />
								<Modal
									style={{ top: 0 }}
									title={FM("Text.SearchHere", "Search here")}
									visible={this.state.searchModalVisible}
									onOk={this.handleOkSearchMobile}
									onCancel={this.handleCancelSearchMobile}
									cancelButtonProps={{ style: { display: 'none' } }}
									okButtonProps={{ style: { display: 'none' } }}>
									<Search onSearch={this.onSelect} />
								</Modal>
								<div className="isoCart" style={{ margin: '0 0 0 1.5em' }}>
									<TopbarAddtoCart deviceView="mobile" url={url} locale={locale} user={this.props.user} />
								</div>
							</div>
						}
						{this.props.view === "DesktopView" && menuAppElements()}
						{this.props.view === "DesktopView" ?
							<ul className="isoRight">
								<li><Search onSearch={this.onSelect} /></li>
								<li className="isoUser">
									<TopbarUser locale={locale} user={this.props.user} showDrawer={this.showDrawer.bind(this)} />
								</li>
								<li className="isoCart">
									<TopbarAddtoCart
										url={url}
										cartDrawerVisible={cartDrawerVisible}
										locale={locale}
										user={this.props.user}
									/>
								</li>
								<li>{toggleLanguageElements()}</li>
							</ul>
							:
							<div className="isoRight">
								<div className={collapsed ? "mobilemenu menuCollapsed" : "mobilemenu menuOpen"}>
									{menuAppElements()}
									<ul className="user-menu" history={this.props.history}>
										<li className="isoUser">
											{this.props.user ?
												<a href='/account'><FormattedMessage id="Menu.My.Account"
													defaultMessge="My account" /></a> :
												<a href='/signin'><FormattedMessage id="Menu.Sign.in" defaultMessage="Sign In" /></a>}
										</li>
										<li>{toggleLanguageElements()}</li>
									</ul>
								</div>
							</div>
						}
					</Header>
					<UserSidebar ref={this.sideMenuRef} user={this.props.user} />
				</TopbarWrapper>
			</IntlProvider>
		);
	}
}

export const MemoizeNewsletter = React.memo(Topbar);
export default connect(
	state => ({
		...state.App,
		...state.Auth,
		...state.Store,
		productQuantity: state.Ecommerce.productQuantity,
		customizedTheme: state.ThemeSwitcher.topbarTheme
	}),
	{
		toggleCollapsed,
		setFilters,
		getCategories,
		getDesigners,
		getOccasions,
		getFormalities,
		getColors,
		getSizes,
		changeLoginPopover,
		closeCollapsed
	}
)(MemoizeNewsletter);
