import styled from 'styled-components';
import { palette } from 'styled-theme';

const AppHolder = styled.div`
  -webkit-overflow-scrolling: touch;
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }
 
  .store-notice{
            background: black;
        color: white;
        padding: 5px;
        text-align: center;
        font-weight: 400;
    }
    .store-notice.hidden{
    display:none;
    }
  .trigger:hover {
    color: ${palette('primary', 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${palette('secondary', 1)};

    &.isoContentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }

  .isoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: #ffffff;
    border: 1px solid ${palette('border', 0)};
    height: 100%;
  }

  .isomorphicLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
      width: 100%;
    }
  }
  .btr-btn{
            border:1px solid #111;
            border-radius:0;
            color:#111;
            min-width:200px;
            display:inline-block;
            height:50px;
            line-height:50px;
            padding:0;
            text-align:center;
   }
  .btr-btn:hover{
            background:#111;        
            color:#fff;
            border:1px solid #111;
   }
   .btr-btn:focus{
      border:1px solid #111 !important;
      color:#fff !important;
   }
  .btr-container{
    padding-left: 50px;
    padding-right: 50px;
        @media only screen and (max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
        }
        @media only screen and (max-width: 500px) {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .ant-table {
      overflow: auto;
      .ant-table-thead > tr > th {
        font-weight: 600;
      }
    }
     .theme-heading{
            margin-bottom: 35px;
            text-align: center;
            font-size: 30px;
            @media only screen and (max-width: 500px) {
                font-size: 22px;
            }
    }
  .ant-layout-footer {
    font-size: 13px;
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
  }

  ${'' /* button {
    border-radius: 0;
  } */};

    .footer{
        background: white;
        padding: 25px 31px;
        .ant-menu{
            li{
                padding-left:0!important;
            }
        }
        .ant-menu:last-child{
            border-right: none;;
        }

        .ant-menu-inline > .ant-menu-item{
            line-height: 20px;
            height: 20px;
            @media only screen and (max-width: 500px){
                padding-right:0;
            } 
        }  
          
        .ant-menu-horizontal{
            margin-left:50px;
            line-height:20px;
            border-bottom:none;
            li{
                border-bottom:none;
                }
        }
        .logo-wrapper{
            text-align:center;
        }
        .footer-menu{
            max-width: 200px;
             margin: auto;
            .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                background-color: #fff;
            }
            .ant-menu-item-selected > a, .ant-menu-item-selected > a:hover {
                    color: rgba(0, 0, 0, 0.65)!important;
            }
            .ant-menu-inline .ant-menu-item:after{
                    border-right: none!important;
            }
            .ant-menu-item a:hover{
                text-decoration:underline!important;
                color:#222!important;
            }
        }
        .social-links-menu ul{
            display:flex;
            flex-wrap:no-wrap;

            li{
                flex-basis:auto;
                    width: auto;
                a i{
                    font-size: 20px;
                }
            }
        }

        @media (min-width: 1025px){
             .logo-wrapper{
                display: flex;
                justify-content: center;
                min-height: 170px;
                align-items: center;
            }
        }
       
        
        @media (max-width: 1024px) {
            .logo-wrapper{
                margin-bottom: 40px;
                margin-top: 30px;
            }
            .ant-col-lg-8{
                width:100%;
            }

            .ant-col-lg-5{
                width:33%;
            }

        }

        @media only screen and (max-width: 500px) {
            .logo-wrapper{
                img{
                    max-width:250px;
                }   
            }
            .ant-col-sm-24{
                width:100%;
                text-align: center;
                margin-bottom: 20px;
            }
            .social-links-menu{
                .ant-menu-inline{
                    justify-content:center;

                    .ant-menu-item{
                        padding-right:0;
                    }   
                }
            }  
            
        }
        .btr-mailing-list{
                h2{
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    margin-top: 10px;
                }
                input{
                    border: 1px solid #000;
                    height: 35px;
                    line-height: 35px;
                    padding: 5px;
                    color: #000;
                    margin-bottom: 5px;
                    width:100%;
                }
                button{
                    width: 100%;
                    height: 35px;
                    line-height: 35px;
                    padding: 0;
                    font-weight: 700;
                    color: #fff;
                    background: #000;
                    border: 1px solid #000;
                    text-transform: uppercase;
                }
            }
    }
 .back-wrapper{
        border-bottom: 1px solid rgba(0,0,0,0.1);
        height:50px;
        a{
            line-height: 50px;
            i{
                    color: #000;
            }
        }
}

    
    .ant-menu-submenu.ant-menu-submenu-popup > .ant-menu {
        .ant-menu-item-selected{
            background-color:#ddd;
        }
    }
        .ant-menu-item-selected{
            background-color:#ddd;
            color:rgba(0,0,0,0.65);
        }

    #item_0$Menu{
        background-color:red;
    }
   


`;

export default AppHolder;
