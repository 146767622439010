import React, { Suspense } from 'react';
import { Map } from 'immutable';
import { loadState } from './localStorage';
import DesignerConverter from "./converter/DesignerConverter";
import { Table } from "antd";
import FirebaseHelper from "./firebase";
import notification from "../components/notification";
import { FM, FN } from "../languageProvider/FMreactIntl";
import ProductsHelper from "../redux/admin/shop/helpers/productsHelper";
import { v4 as uuidv4 } from 'uuid';

const db = FirebaseHelper.database;

export function clearToken() {
  localStorage.removeItem('id_token');
}

export async function updateProductAfterOrderPlaced(products) {
  for (const [key, value] of Object.entries(products)) {
    let update = {};
    if (value.is_variant) {
      value.variants.forEach((row) => {
        if (row.size === value.size && row.color === value.color) {
          row.quantity -= value.quantity;
        }
      });
      update.variants = value.variants;
      update.numIndex = key;
    } else {
      update.quantity -= value.quantity;
      update.numIndex = key;
    }
    await db.collection('products').doc(value.uid).set(update, {merge: true});
  }
}

export function fetchUserAddressList(self, uid) {
  db.collection("users").doc(uid).get().then(doc => {
    if (!doc.exists) {
      // console.log('fetchUserAddressList: No such document!');
    } else {
      try {
        let addresses = doc.data().address_list;
        if (addresses.length > 0) {
          self.setState({addressList: addresses, isAddressLoading: false});
        } else {
          self.setState({isAddressLoading: false});
        }
      } catch (e) {
        // console.log(e);
        self.setState({isAddressLoading: false});
      }
    }
  });
}

export function getIsSubscribed(self, customerId) {
  let cId = null;
  try {
    cId = customerId;
    if (cId === null) {
      throw new Error('Not logged in or customer not exist!');
    } else {
      fetchStripeCustomer(cId).then(async r => {
        try {
          if (r.subscriptions.data.length > 0) {
            if (r.subscriptions.data[0]['status'] === 'active') {
              self.setState({isSubscribed: true});
            }
          }
        } catch (err) {
          console.log(err);
        }
      }).catch(e => console.log(e));
    }
  } catch (e) {
    // console.error(e);
  }
}

// used in buy now and gift coupons
export function createOrderObject(customer, product, total, shipping_info, used_for) {
  db.runTransaction(async () => {
    const o = {};
    const orderID = uuidv4();
    o.order_id = orderID.substring(0, 5);
    o.createdAt = new Date();
    o.name = customer.first_name + ' ' + customer.last_name;
    o.email = customer.email;
    o.plan = false;
    o.plan_id = used_for;
    o.total = total;
    o.status = "Processing";
    o.user = customer.uid;
    o.shipping_information = {
      address: shipping_info.shipping_address,
      address2: shipping_info.shipping_address2,
      city: shipping_info.shipping_city,
      province: shipping_info.shipping_province,
      postal_code: shipping_info.shipping_postal_code,
      country: shipping_info.shipping_country,
      phone_number: shipping_info.shipping_phone_number
    };
    o.products = [product];
    await db.collection("orders").doc().set(o);
  }).catch(e => {
    console.error(e);
  });
}

export function createProductsOrder(orderedProducts) {
  let products = [];
  for (const [key, value] of Object.entries(orderedProducts)) {
    let product = {};
    if (value.id.indexOf("-")) {
      product.id = value.id.substring(0, value.id.indexOf("-"));
    } else {
      product.id = value.id;
    }
    product.overLimitPrice = false;
    product.bagOrdered = false;
    // for luxury items verification
    if (value.price > 999) {
      product.overLimitPrice = true;
    }
    // for two bags a month validation
    if ('categories' in value) {
      if (value.categories.length && value.categories.includes('TPN7vyy2jj13PPTJgsHh')) {
        product.bagOrdered = true;
      }
    }
    product.name = value.name;
    product.description = value.description;
    product.quantity = value.quantity;
    product.size = value.size;
    product.color = value.color;
    product.sku = value.sku;
    product.price = value.price;
    product.numIndex = key;
    if ('product_image' in value) {
      product.product_image = value.product_image;
    }
    products.push(product);
  }
  return products;
}

export function clearUser() {
  localStorage.removeItem('user');
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token');
    return new Map({idToken});
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function getUser() {
  try {
    const user = loadState('user');
    return new Map({user});
  } catch (err) {
    clearUser();
    return new Map();
  }
}

export function capitalizeFirstLetter(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function updateURLParameter(url, param, paramVal) {
  let newAdditionalURL = "";
  let tempArray = url.split("?");
  const baseURL = tempArray[0];
  const additionalURL = tempArray[1];
  let temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (let i = 0; i < tempArray.length; i++) {
	  if (tempArray[i].split('=')[0] !== param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
	  }
    }
  }

  const rows_txt = temp + "" + param + "=" + paramVal;
  return baseURL + "?" + newAdditionalURL + rows_txt;
}

export function convertToCents(num) {
  let nArr = num.split("");
  nArr.splice(nArr.indexOf("."), 1);
  return nArr.join("");
}

export function convertObjToJSON(object) {
  let json = JSON.stringify(object);
  let blob = new Blob([json], {type: "application/json"});
  let url = URL.createObjectURL(blob);

  let a = document.createElement('a');
  a.download = "backup.json";
  a.href = url;
  a.textContent = "Download backup.json";
  return a;
}

export function isValidEmail(email) {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function productURL(productName, productId) {
  let removeHyphenDot = productName.trim().replace(/-/g, " ").replace(/[.]/g, " ");
  // eslint-disable-next-line
  let removeSlashes = removeHyphenDot.replace(/[\/]/g, " "); // replace all forward all backward slash
  let removeWhitespacesInBetween = removeSlashes.replace(/\s+/g, " ");
  return `/shop/products/${removeWhitespacesInBetween.split(' ').join('-').toLowerCase()}/${productId}`;
}

export function createFBDataFeed(products) {
  let convertedData = [];
  for (let i = 0; i < products.length; i++) {
    let designerConverter = new DesignerConverter(null, products[i]['designer']);
    let designerName = designerConverter.getObjById().name;
    let priceStr = products[i]['price'];

    let imgLink = products[i]['product_thumb'];
    if (imgLink === '' || imgLink === undefined || imgLink <= 0) {
	  imgLink = 'https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/no-product-image.jpg?alt=media&token=1fa5092a-108e-4b61-b8a7-2ff05e0e0ae3';
    }

    let productBrand = "Beyond the Runway";
    if (designerName !== undefined || designerName !== "") {
	  productBrand = designerName;
    }

    convertedData.push({
	  id: products[i]['id'],
	  title: products[i]['name'],
	  description: (products[i]['description']).replace(/<[^>]+>/g, ''),
	  availability: 'available for order',
	  condition: 'new',
	  price: priceStr.toString().concat(' CAD'),
	  link: `https://beyondtherunway.ca/shop/${products[i]['id']}`,
	  image_link: imgLink,
	  brand: productBrand,
	  additional_image_link: products[i]['gallery_images']
    });
  }

  return createDownloadable(convertedData);
}

export function createDownloadable(array) {
  let json = JSON.stringify(array);
  let blob = new Blob([json], {type: "application/json"});
  let url = URL.createObjectURL(blob);

  let a = document.createElement('a');
  a.download = "backup.json";
  a.href = url;
  a.textContent = "Download backup.json";
  return a;
}

export function productPricing(product) {
  let pricefive = 60;
  let priceten = 80;
  if (product.price > 300 && product.price < 701) {
    pricefive = 80;
    priceten = 100;
  } else if (product.price > 700 && product.price < 1501) {
    pricefive = 120;
    priceten = 150;
  } else if (product.price > 1500) {
    pricefive = 150;
    priceten = 200;
  }
  return product.period === 10 ? priceten : pricefive;
}

/**
 * @param {*} price - int: rental price
 * @param {*} period - int: rental period
 * @param {*} categories - array (optional): product categories
 * @returns returns rental price
 */
export function productPricingV2(price, period = 5, categories = [], currentProductId = "", customPriceProducts = []) {
  // console.log(categories, "<=category");
  // console.log(price, "<=price");
  // console.log(period, "<=period");

  let pricePeriod = { priceFive: 80, priceTen: 100 };
  if (price > 300 && price < 701) {
    pricePeriod.priceFive = 100;
    pricePeriod.priceTen = 120;
  } else if (price > 700 && price < 1501) {
    pricePeriod.priceFive = 120;
    pricePeriod.priceTen = 150;
  } else if (price > 1500) {
    pricePeriod.priceFive = 150;
    pricePeriod.priceTen = 200;
  }

  // for bags with category id TPN7vyy2jj13PPTJgsHh
  if (categories.includes("TPN7vyy2jj13PPTJgsHh") && price >= 3000 && price < 5000) {
    pricePeriod.priceFive = 150;
    pricePeriod.priceTen = 200;
  } else if (categories.includes("TPN7vyy2jj13PPTJgsHh") && price >= 5000) {
    pricePeriod.priceFive = 250;
    pricePeriod.priceTen = 300;
  }

  // CUSTOM PRICE PRODUCTS ========================================
  try {
    if (!customPriceProducts.length && currentProductId !== "") return;
    if (customPriceProducts.find(({ productId }) => productId === currentProductId)) {
      const customPriceProduct = customPriceProducts.find(({ productId }) => productId === currentProductId);
      pricePeriod.priceFive = customPriceProduct.priceFive;
      pricePeriod.priceTen = customPriceProduct.priceTen;
    }
  } catch (error) {
    console.log(error);
  }
  // ===========================================================

  return period <= 8 ? pricePeriod.priceFive : pricePeriod.priceTen;
}

export function oneTimeCalculation() {
  const oneTimeTable = [
    {key: '1', retailPrice: <>{FN(1)}-{FN(300)}</>, fiveDaysRental: FN(80), tenDaysRental: FN(100)},
    {key: '2', retailPrice: <>{FN(301)}-{FN(700)}</>, fiveDaysRental: FN(100), tenDaysRental: FN(120)},
    {key: '3', retailPrice: <>{FN(701)}-{FN(1500)}</>, fiveDaysRental: FN(120), tenDaysRental: FN(150)},
    {key: '4', retailPrice: <>{FN(1500)}+</>, fiveDaysRental: FN(150), tenDaysRental: FN(200)}];

  const oneTimeColumns = [
    { title: FM("Text.RetailPrice", "Retail price"), dataIndex: 'retailPrice', key: 'retail' },
    { title: FM("Text.5Days", "5 days"), dataIndex: 'fiveDaysRental', key: 'fiveDayRental' },
    { title: FM("Text.10Days", "10 days"), dataIndex: 'tenDaysRental', key: 'tenDaysRental' }];

  const oneTimeColumns5k = [
    { title: "1 time Luxury Bag Rental", dataIndex: 'retailPrice', key: '1 time Luxury Bag Rental' },
    { title: FM("Text.5Days", "5 days"), dataIndex: 'fiveDaysRental', key: 'fiveDayRental' },
    { title: FM("Text.10Days", "10 days"), dataIndex: 'tenDaysRental', key: 'tenDaysRental' }];

  const oneTimeTable5k = [
    {
      key: '1', retailPrice: "Bags worth more than $3,000.00 *identity verification needed / Sacs d'une valeur de 3 000 $ et plus, *Vérification d'identité nécessaire",
      fiveDaysRental: FN(150), tenDaysRental: FN(200)
    }, {
      key: '2', retailPrice: "Bags worth more than $5,000.00 *identity verification needed / Sacs d'une valeur de 5 000 $ et plus, *Vérification d'identité nécessaire",
      fiveDaysRental: FN(250), tenDaysRental: FN(300)
    }];


  return (
    <>
      <Table dataSource={oneTimeTable} pagination={false} columns={oneTimeColumns} />
      <Table dataSource={oneTimeTable5k} pagination={false} columns={oneTimeColumns5k} />
    </>
  );
}

export function galleryGetBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function makeId(length, isNumOnly = false) {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  if (isNumOnly === true) {
    characters = "0123456789";
  }
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function suspenseWrapper(component) {
  return <Suspense fallback={<div>Loading...</div>}>
    {component}
  </Suspense>;
}

export async function fetchStripeCustomer(customerId) {
  const response = await fetch(`https://api.stripe.com/v1/customers/${customerId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer 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'
    }
  });
  return response.json();
}

export function notif(type, en, fr, currentLang = "en") {
  if (localStorage.getItem("currentLang") === "en" || currentLang === "en") {
    notification(type, en, "", "topLeft", 10);
  } else {
    notification(type, fr, "", "topLeft", 10);
  }
}

export function getPlanDetails() {
  return {
    basic: [FM("OneTime.Rental1", "One-time rental"),
      FM("OneTime.Rental2", "Rent items for either 5 or 10 days"),
      FM("OneTime.Rental4", "Return rented items in 5 or 10 days"),
      FM("OneTime.Rental3", "Rates depend on the value of items & period")],
    silver: [FM("Common.upTo6.months", "Keep items up to 6 months"),
      FM("Common.Min2Months", "Minimum 2 months commitment"),
      FM("Common.upTo4.items", "Rent up to 4 items from our closet"),
      FM("Silver.Rental", "Includes 2 rentals per month (up to 8 items)"),
      FM("Silver.Rental2", "Luxury bags over $1,000.00 not allowed")],
    gold: [FM("Common.upTo6.months", "Keep items up to 6 months"),
      FM("Common.Min2Months", "Minimum 2 months commitment"),
      FM("Common.upTo4.items", "Rent up to 4 items from our closet"),
      FM("Gold.Rental3", "$1000+ must be returned at the end of month"),
      FM("Gold.Rental1", "Includes unlimited swap throughout the month"),
      FM("Gold.Rental2", "Exclusive access to luxury items worth $1000+"),
      FM("Gold.Rental4", "One luxury bag/month allowed")]
  };
}

export function getEnFrStatus(text) {
  if (text === "Returned") {
    return "Returned / Renvoyé";
  } else if (text === "Processing") {
    return "Processing / En traitement";
  } else if (text === "Pick Up") {
    return "Pick Up";
  } else if (text === "Shipped") {
    return "Shipped / Votre commande a été expédiée.";
  } else if (text === "Incomplete") {
    return "Incomplete / Incomplet";
  } else if (text === "Paid") {
    return "Paid / Payé";
  } else if (text === "Cancelled") {
    return "Cancelled / Annulé";
  } else {
    return text;
  }
}

export function timeDiffCalc(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
  return Math.floor(diffInMilliSeconds / 86400);
}

export async function handleImageCustomRequest(file, onSuccess, onError) {
  const payload = {"file": file};
  const data = await ProductsHelper.addProductImage(payload);
  file.url = data;
  if (data && !data.error) {
    onSuccess(null, file);
  } else {
    onError(data.error);
    notif("error", data.error, data.error);
  }
}

export function isSubscribedSilverPlan(currentPlan) {
  return currentPlan === process.env.REACT_APP_SILVER_PLAN_API_ID;
}

export function isSubscribedGoldPlan(currentPlan) {
  return currentPlan === process.env.REACT_APP_GOLD_PLAN_API_ID;
}

export function notAvailableStatus() {
  let msg = 'UNAVAILABLE';
  if (localStorage.getItem("currentLang") === "fr") {
    msg = 'NON DISPONIBLE';
  } else {
    msg = 'UNAVAILABLE';
  }
  return msg.toUpperCase();
}

export const CUSTOM_PRICE_PRODUCTS = [{
  productId: "qvouuGBZNOsWNDnGCJQp",
  priceFive: 300,
  priceTen: 370
}]

export function isDevMode() {
  return process.env.NODE_ENV === 'development' && window.location.hostname.includes("local");
}

export function generateFiltersQueryString(filters = {}) {
  let queryString = "";
  if (filters && Object.keys(filters).length > 0) {
    const qs = [];
    const filterKeys = Object.keys(filters);
    for (let i = 0; i < filterKeys.length; i++) {
      const keyValues = filters[filterKeys[i]];
      for (let index = 0; index < keyValues.length; index++) {
        const element = keyValues[index];
        qs.push(filterKeys[i] + "=" + element);
      }
    }
    queryString = '?' + qs.join('&');
  }
  return queryString;
}

export function pushStateFiltersQueryString(filters = {}) {
  const queryString = generateFiltersQueryString(filters);
  if (!queryString) {
    console.error('Error: query string cannot be empty or null');
    return;
  }
  
  const urlWithoutQuery = window.location.href.split('?')[0];
  const newUrl = urlWithoutQuery + queryString;
  try {
    window.history.pushState(null, null, newUrl);
  } catch (e) {
    console.error('Error pushing state:', e);
  }
}

/**
 * @param {URLSearchParams} params 
 * @returns '{obj: [], ob2: []}' where obj{} is filter
 */
export const groupParamsByKey = (params) => {
  const result = {};
  for (const [key, value] of params) {
    if (result.hasOwnProperty(key)) {
      if (Array.isArray(result[key])) {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = [value];
    }
  }
  return result;
}

/**
 * @returns filters, for example: '{color: [], size: []}' from the URL search params
 */
export const getFilterObjBySearchParam = () => {
  const searchParams = window.location.search;
  const urlSearchParams = new URLSearchParams(searchParams);
  const filtersObj = groupParamsByKey(urlSearchParams);
  return filtersObj;
}