import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../image/logo-btr-white.png';
import icon from '../../image/btr_logo-icon.png';
export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        
          
        <Link to="/dashboard">
          <img src={icon} alt="BTR LOGO"/>
        </Link>
         
       
      ) : (
        <Link to="/dashboard"><img src={Logo} alt="BTR LOGO"/></Link>
       
      )}
    </div>
  );
};
