const actions = {
  INIT_DATA: 'ECOMMERCE_INIT_DATA',
  INIT_DATA_SAGA: 'ECOMMERCE_INIT_DATA_SAGA',
  UPDATE_DATA: 'ECOMMERCE_UPDATE_DATA',
  UPDATE_PRODUCT_PERIOD : 'UPDATE_PRODUCT_PERIOD',
  UPDATE_DATA_SAGA: 'ECOMMERCE_UPDATE_DATA_SAGA',
  UPDATE_QUANTITY_SAGA: 'UPDATE_QUANTITY_SAGA',
  CHANGE_VIEW: 'ECOMMERCE_CHANGE_VIEW',
  VIEW_TOPBAR_CART: 'ECOMMERCE_VIEW_TOPBAR_CART',
  ORDER_SUBSCRIPTION : 'ORDER_SUBSCRIPTION', 
  ORDER_PRODUCTS : 'ORDER_PRODUCTS',
  LOADING : 'LOADING',
  ORDER_PLACED : 'ORDER_PLACED',
    
  initData: () => ({ type: actions.INIT_DATA_SAGA }),
  changeView: view => ({
    type: actions.CHANGE_VIEW,
    view
  }),
  changeViewTopbarCart: viewTopbarCart => {
    return {
      type: actions.VIEW_TOPBAR_CART,
      viewTopbarCart
    };
  },
  updateProductPeriod: payload => {
      return {
        type: actions.UPDATE_PRODUCT_PERIOD,
        payload
      };
  },
  updateProductCart: payload => {
    return (dispatch, getState) => {
    var { products, newProductQuantity } = payload;
    var productQuantity = newProductQuantity
      dispatch({
        type: actions.UPDATE_QUANTITY_SAGA,
        products,
        productQuantity
      });
    };
  },
  changeProductQuantity: productQuantity => {
    return (dispatch, getState) => {
    var { products } = getState().Ecommerce;
    productQuantity.map((prod, index) => {
        if(prod.id in products ){
            products[prod.id].quantity = prod.quantity;
        }
        return null;
    })
      dispatch({
        type: actions.UPDATE_QUANTITY_SAGA,
        products,
        productQuantity
      });
    };
  },
  addToCart: product => {
    return (dispatch, getState) => {
      const { products, productQuantity } = getState().Ecommerce;
      const id = product.id;
      
      if(id in products){
          var pq = productQuantity.find(o => o.id === id);
          if(pq){
              pq.quantity = products[id].quantity + 1;
          }
          products[id].quantity = products[id].quantity + 1;
      }else{
        productQuantity.push({ id, quantity: 1 });
        products[id] = product;
      }
      
      dispatch({
        type: actions.UPDATE_DATA_SAGA,
        products,
        productQuantity
      });
    };
  },
  orderSubscription : payload => ({
    type: actions.ORDER_SUBSCRIPTION,
    payload
  }),
  orderProducts : payload => ({
    type: actions.ORDER_PRODUCTS,
    payload
  }),
  orderPlaced : payload => ({
    type: actions.ORDER_PLACED,
    payload
  }),
   loading:  () => ({
    type: actions.LOADING
  }),
  
    
};
export default actions;
