import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import Loadable from "react-loadable";
import {LOAD_TEXT} from "../../settings";

const loadableAdminPages = {
  products: Loadable({
	loader: () => import('../ProductsAdmin'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  product: Loadable({
	loader: () => import('../ProductsAdmin/product'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  addProduct: Loadable({
	loader: () => import('../ProductsAdmin/addProduct'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  bulkImport: Loadable({
	loader: () => import('../ProductsAdmin/bulkImport'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  categories: Loadable({
	loader: () => import('../ProductsAdmin/categories'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  colors: Loadable({
	loader: () => import('../ProductsAdmin/colors'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  sizes: Loadable({
	loader: () => import('../ProductsAdmin/sizes'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  occasions: Loadable({
	loader: () => import('../ProductsAdmin/occasions'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  formalities: Loadable({
	loader: () => import('../ProductsAdmin/formality'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  designers: Loadable({
	loader: () => import('../ProductsAdmin/designers'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  orders: Loadable({
	loader: () => import('../ProductsAdmin/orders'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  order: Loadable({
	loader: () => import('../ProductsAdmin/order'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  users: Loadable({
	loader: () => import('../ProductsAdmin/users'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  user: Loadable({
	loader: () => import('../ProductsAdmin/user'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  settings: Loadable({
	loader: () => import('../ProductsAdmin/settings'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  reviews: Loadable({
	loader: () => import('../ProductsAdmin/reviews'),
	loading() {
	  return LOAD_TEXT;
	}
  }),
  editReview: Loadable({
	loader: () => import('../ProductsAdmin/editReview'),
	loading() {
	  return LOAD_TEXT;
	}
  })
};

const routes = [
  {
	path: 'products',
	component: loadableAdminPages.products
  },
  {
	path: 'products/:productId',
	component: loadableAdminPages.product
  },
  {
	path: 'product/add',
	component: loadableAdminPages.addProduct
  },
  {
	path: 'product/bulk-import',
	component: loadableAdminPages.bulkImport
  }, {
	path: 'categories',
	component: loadableAdminPages.categories
  }, {
	path: 'colors',
	component: loadableAdminPages.colors
  }, {
	path: 'sizes',
	component: loadableAdminPages.sizes
  }, {
	path: 'occasions',
	component: loadableAdminPages.occasions
  }, {
	path: 'formalities',
	component: loadableAdminPages.formalities
  }, {
	path: 'designers',
	component: loadableAdminPages.designers
  },
  {
	path: 'orders',
	component: loadableAdminPages.orders
  }, {
	path: 'orders/:orderId',
	component: loadableAdminPages.order
  },
  {
	path: 'users',
	component: loadableAdminPages.users
  },
  {
	path: 'users/:userId',
	component: loadableAdminPages.user
  },
  {
	path: 'settings',
	component: loadableAdminPages.settings
  },
  {
	path: 'reviews',
	component: loadableAdminPages.reviews
  },
  {
	path: 'reviews/:reviewId',
	component: loadableAdminPages.editReview
  }
];

class AppRouter extends Component {

  render() {
	const {url, style} = this.props;
	return (
		<div style={style}>
		  {routes.map(singleRoute => {
			const {path, exact, ...otherProps} = singleRoute;
			return (
				<Route
					exact={exact !== false}
					key={singleRoute.path}
					path={`${url}/${singleRoute.path}`}
					{...otherProps}
				/>
			);
		  })}
		</div>
	);
  }
}

export default AppRouter;
