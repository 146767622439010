import React, {Component} from 'react';
import {connect} from 'react-redux';
import Popover from '../../components/uielements/popover';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import {Icon} from 'antd';

const {logout} = authAction;

class TopbarUser extends Component {
  constructor(props) {
	super(props);
	this.handleVisibleChange = this.handleVisibleChange.bind(this);
	this.hide = this.hide.bind(this);
	this.state = {
	  visible: false,
	};
  }

  hide() {
	this.setState({visible: false});
  }

  handleVisibleChange() {
	this.setState({visible: !this.state.visible});
  }

  render() {
	const content = (
		<TopbarDropdownWrapper className="isoUserDropdown">
		  <a className="isoDropdownLink" href="/">View Site</a>
		  <a className="isoDropdownLink" onClick={this.props.logout} href="/">Logout</a>
		</TopbarDropdownWrapper>
	);

	return (
		<Popover
			content={content}
			trigger="click"
			visible={this.state.visible}
			onVisibleChange={this.handleVisibleChange}
			arrowPointAtCenter={true}
			placement="bottomLeft"
		>
		  <div className="isoImgWrapper">
			<Icon type="user"/>
			<span className="userActivity online"/>
		  </div>
		</Popover>
	);
  }
}

export default connect(
	null,
	{logout}
)(TopbarUser);
