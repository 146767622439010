import React, { Component } from "react";
import { connect } from "react-redux";
import SingleCartModal from "../../components/cart/singleCartModal";
import ecommerceAction from "../../redux/ecommerce/actions";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import { Button, Drawer, Icon, Badge } from "antd";
import { FM } from "../../languageProvider/FMreactIntl";
import FirebaseHelper from "../../helpers/firebase";
import { fetchStripeCustomer } from '../../helpers/utility';
import TopbarCartWrapper from '../../components/cart/singleCartModal.style';
import NoProductImage from '../../image/no-product-image.webp';
import '../../containers/Page/bootstrap.min.css';
import * as BuySellFn from "../../helpers/helperFunctionsBuySell";

const db = FirebaseHelper.database;
const {
	initData,
	changeViewTopbarCart,
	changeProductQuantity,
	updateProductCart
} = ecommerceAction;
const shoppingBagSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 23.73l-3-2.122v-14.2l3 1.359v14.963zm2-14.855v15.125l13-1.954v-15.046l-13 1.875zm5.963-7.875c-2.097 0-3.958 2.005-3.962 4.266l-.001 1.683c0 .305.273.54.575.494.244-.037.425-.247.425-.494v-1.681c.003-1.71 1.416-3.268 2.963-3.268.537 0 1.016.195 1.384.564.422.423.654 1.035.653 1.727v1.747c0 .305.273.54.575.494.243-.037.423-.246.423-.492l.002-1.749c.002-1.904-1.32-3.291-3.037-3.291zm-6.39 5.995c.245-.037.427-.247.427-.495v-2.232c.002-1.71 1.416-3.268 2.963-3.268l.162.015c.366-.283.765-.513 1.188-.683-.405-.207-.858-.332-1.35-.332-2.096 0-3.958 2.005-3.962 4.266v2.235c0 .306.272.538.572.494z" /></svg>);

class TopbarAddtoCart extends Component {
	constructor(props) {
		super(props);
		this.handleVisibleChange = this.handleVisibleChange.bind(this);
		this.hide = this.hide.bind(this);
		this.renderProducts = this.renderProducts.bind(this);
		this.changeQuantity = this.changeQuantity.bind(this);
		this.cancelQuantity = this.cancelQuantity.bind(this);
		this.handleClickViewShoppingBag = this.handleClickViewShoppingBag.bind(this);
		this.state = {
			cartDrawer: false,
			sellerProducts: []
		}
	}

	hide() {
		this.props.changeViewTopbarCart(false);
	}

	handleClickViewShoppingBag() {
		if (this.props.user === null) {
			window.location.href = window.location.origin.concat('/clients-cart');
		} else {
			let self = this;
			let user = self.props.user;
			db.collection('users').doc(user.uid).get().then(doc => {
				let dd = doc.data();
				if (dd.customerId !== undefined) {
					fetchStripeCustomer(user.customerId).then(async r => {
						if (r.subscriptions.total_count > 0) {
							window.location.href = window.location.origin.concat('/cart');
						} else {
							window.location.href = window.location.origin.concat('/clients-cart');
						}
					}).catch(e => {
						console.log(e);
						window.location.href = window.location.origin.concat('/clients-cart');
					});
				} else {
					window.location.href = window.location.origin.concat('/clients-cart');
				}
			});
		}
	}

	handleVisibleChange() {
		this.props.changeViewTopbarCart(!this.props.viewTopbarCart);
	}

	componentDidMount() {
		const { loadingInitData, initData } = this.props;
		if (!loadingInitData) {
			initData();
		}
	}

	removeItemBuySellCart = (pid) => {
		BuySellFn.removeFromCart(pid);
		let products = this.state.sellerProducts.filter(p => p.id !== pid);
		this.setState({ sellerProducts: products });
	}

	renderProducts() {
		const { productQuantity, products } = this.props;
		if (!productQuantity || productQuantity.length === 0) {
			return <div className="isoNoItemMsg mt-3 mb-3">{FM("Text.NoItems.Found", "No items here yet.")}</div>;
		} else {
			return productQuantity.map(product => {
				return <SingleCartModal
					key={product.id}
					quantity={product.quantity}
					changeQuantity={this.changeQuantity}
					cancelQuantity={this.cancelQuantity}
					{...products[product.id]}
				/>;
			});
		}
	}

	renderBuySellProducts() {
		let products = this.state.sellerProducts;
		if (products.length > 0) {
			return products.map((p, i) =>
				<TopbarCartWrapper className="isoCartItems">
					<div key={i} className="isoItemImage">
						<img alt="#" src={p.gallery_images[0] ? p.gallery_images[0] : NoProductImage} />
					</div>
					<div className="isoCartDetails">
						<h3><a href={`/buy-sell/${p.category.toLowerCase()}/${p.id}`}>{p.item_name}</a></h3>
						<p className="isoItemPriceQuantity">
							<span>Item Condition: {p.condition}</span>
						</p>
					</div>
					<a className="isoItemRemove" onClick={this.removeItemBuySellCart.bind(this, p.id)} href="#!"><Icon type="close" /></a>
				</TopbarCartWrapper>
			);
		} else {
			return <div className="isoNoItemMsg mt-3 mb-3">{FM("Text.NoItems.Found", "No items here yet.")}</div>;
		}
	}

	changeQuantity(id, quantity) {
		const { productQuantity } = this.props;
		const newProductQuantity = [];
		productQuantity.forEach(product => {
			if (product.id !== id) {
				newProductQuantity.push(product);
			} else {
				newProductQuantity.push({
					id,
					quantity
				});
			}
		});
		this.props.changeProductQuantity(newProductQuantity);
	}

	cancelQuantity(id) {
		const { productQuantity, products } = this.props;
		delete products[id];
		const newProductQuantity = [];
		productQuantity.forEach(product => {
			if (product.id !== id) {
				newProductQuantity.push(product);
			}
		});
		this.props.updateProductCart({ newProductQuantity, products });
	}

	closeCartDrawer = () => this.setState({ cartDrawer: false });
	openCartDrawer = () => {
		let products = [];
		if (localStorage.getItem('buySellproducts') !== null) {
			products = JSON.parse(localStorage.getItem('buySellproducts'));
		}
		this.setState({ cartDrawer: true, sellerProducts: products });
	}

	render() {
		const { productQuantity, deviceView, } = this.props;
		const { cartDrawer } = this.state;
		const view = this.props.view;
		const content = (
			<>
				<TopbarDropdownWrapper className="topbarAddtoCart" style={{
					width: view === "DesktopView" ? "inherit" : "100%",
					margin: view === "DesktopView" ? "inherit" : "0"
				}}>
					<div className="isoDropdownHeader">
						<h3>{FM("Text.RentalShoppingBag", "RENTAL SHOPPING BAG")}</h3>
					</div>
					{this.renderProducts()}
					<Button className="ant-btn btr-black-btn" style={{ marginTop: 0 }}
						onClick={this.handleClickViewShoppingBag}>{FM("Text.ViewRentalShoppingBag", "VIEW RENTAL SHOPPING BAG")}</Button>
				</TopbarDropdownWrapper>
				<p style={{
					marginTop: "2em",
					marginBottom: "2em",
					border: "1px dashed #eee",
					marginLeft: "-1em",
					marginRight: "-1em"
				}}></p>
				<TopbarDropdownWrapper className="topbarAddtoCart" style={{
					width: view === "DesktopView" ? "inherit" : "100%",
					margin: view === "DesktopView" ? "inherit" : "0"
				}}>
					<div className="isoDropdownHeader">
						<h3>{FM("Text.BuySellShoppingBag", "BUY AND SELL SHOPPING BAG")}</h3>
					</div>
					{this.renderBuySellProducts()}
					<a className="ant-btn btr-black-btn" style={{ marginTop: 0 }}
						href="/buy-sell-cart">{FM("Text.ViewBuySellShoppingBag", "VIEW BUY AND SELL SHOPPING BAG")}</a>
				</TopbarDropdownWrapper>
			</>
		);
		return (
			<>
				{deviceView === "mobile" ?
					<div className="isoIconWrapper">
						<Badge onClick={this.openCartDrawer} showZero style={{
							fontSize: '12px',
							color: '#fff',
							backgroundColor: '#F75D81',
							width: '20px',
							height: '20px',
							textAlign: 'center',
							lineHeight: '20px',
							borderRadius: '50%'
						}} count={productQuantity.length === 0 ? ('') : (<span>{productQuantity.length}</span>)}>
							<Icon component={shoppingBagSvg} onClick={this.openCartDrawer} />
						</Badge>
					</div> :
					<div className="isoIconWrapper">
						<Icon component={shoppingBagSvg} onClick={this.openCartDrawer} />
						{productQuantity.length === 0 ? (
							""
						) : (
								<span>{productQuantity.length}</span>
							)}
					</div>
				}
				<Drawer
					title={FM("Text.View.ShoppingBag", "SHOPPING BAG")}
					visible={cartDrawer}
					onClose={this.closeCartDrawer}
					placement="right"
					closable={true}
					width={view === "DesktopView" ? "500px" : "100%"}
				>
					{content}
				</Drawer>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		...state.Ecommerce,
		customizedTheme: state.ThemeSwitcher.topbarTheme,
		view: state.App.view
	};
}

export default connect(mapStateToProps, {
	initData,
	changeViewTopbarCart,
	changeProductQuantity,
	updateProductCart
})(TopbarAddtoCart);
