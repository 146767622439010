import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '../../components/uielements/popover';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { Icon } from 'antd';
import { FormattedMessage } from "react-intl";

const { logout } = authAction;

class TopbarUser extends Component {
	constructor(props) {
		super(props);
		this.handleVisibleChange = this.handleVisibleChange.bind(this);
		this.hide = this.hide.bind(this);
		this.showReferralModal = this.showReferralModal.bind(this);
		this.handleCancelReferralModal = this.handleCancelReferralModal.bind(this);
		this.state = {
			visible: false,
			modalVisible: false,
			confirmModalLoading: false
		};
	}

	hide() {
		this.setState({ visible: false });
	}

	handleVisibleChange() {
		this.setState({ visible: !this.state.visible });
	}

	showReferralModal() {
		this.setState({
			modalVisible: true
		});
	}

	handleCancelReferralModal() {
		this.setState({
			modalVisible: false
		});
	}

	adminlink() {
		if (this.props.user && Object.keys(this.props.user).includes('is_admin')) {
			return <a className="isoDropdownLink" href='/admin'><FormattedMessage id="Menu.My.Admin" defaultMessage="Admin dashboard" /></a>
		} else {
			return ""
		}
	}

	topBarContent() {
		return <TopbarDropdownWrapper className="isoUserDropdown">
			<a className="isoDropdownLink" href='/account'><FormattedMessage id="Menu.My.Account" defaultMessage="My account" /></a>
			<a className="isoDropdownLink" href='/faq'><FormattedMessage id="Menu.Help" defaultMessage="Need help?" /></a>
			{this.adminlink()}
			<a className="isoDropdownLink" onClick={this.props.logout} href={"#logout"}>
				<FormattedMessage id="Menu.Logout" defaultMessage="Logout" />
			</a>
		</TopbarDropdownWrapper>;
	}

	render() {
		
		return (
			<div className="top-bar-user">
				{this.props.user ?
					<>
						<Popover
							content={this.topBarContent()}
							trigger="click"
							visible={this.state.visible}
							onVisibleChange={this.handleVisibleChange}
							arrowPointAtCenter={true}
							placement="bottomLeft">
							<div className="isoImgWrapper">
								<Icon type="user" />
								<span className="userActivity online" />
							</div>
						</Popover>
					</>
					:
					<a onClick={this.props.showDrawer} href={"#login-signup"}>
						<FormattedMessage id="Menu.Sign.in" defaultMessage="Sign In" />
					</a>
				}
			</div>
		);
	}
}

export default connect(
	null,
	{ logout }
)(TopbarUser);
