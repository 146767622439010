import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const SigninWrapper = styled.div`
       
        .sidemenu-header{
            text-align:center;
        }
        .sidemenu-login-row-wrapper{
            display:flex;
        }
        .sidemenu-login-wrapper{
             max-width: 400px;
            margin:auto;
           
             .login-form-forgot {
                float: right;
            }
            .login-form-button {
              width: 100%;
            }
            .login-form-wrapper{
                margin-top:25px;
            }
            .ant-input-affix-wrapper .ant-input {
                height: 50px;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #111;
                border-color: #111;
            }
            .new-account-wrapper{
                text-align:center;
                margin-top: 25px;
            }
        }
         .btr-btn{
                    border:1px solid #111;
                    border-radius:0;
                    color:#111;
                    min-width:200px;
                    display:inline-block;
                    margin-top:25px;
                    height:50px;
                    line-height:50px;
                    padding:0;
                    text-align:center;
                    background:#fff;
           }
          .btr-btn:hover, .btr-btn:focus{
                    background:#111;        
                    color:#fff;
                        border-color: #111;
           }

    
`;

export default WithDirection(SigninWrapper);


